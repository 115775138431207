import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/FinancialManagement.css";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { GrFormEdit } from "@react-icons/all-files/gr/GrFormEdit";

import { couponupdatesuperadmin, coupondeletesuperadmin } from "../Services";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function CouponCreator({ editcoupon }) {
  //const [hide, setHide] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [id, setId] = useState(editcoupon?.id);

  const [deletecoupon, setDeleteCoupon] = useState(editcoupon?.id);

  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [userlimit, setUserLimit] = useState("");
  const [coupontype, setCoupontype] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [persentage, setPercentage] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hideProfile, setHideProfile] = useState("");
  const [deletemodal, setDeleteModal] = useState(false);

  useEffect(
    (e) => {
      setId(editcoupon?.id);
      setCode(editcoupon?.code);
      setDescription(editcoupon?.description);
      setCoupontype(editcoupon?.deal_type);
      setStartDate(editcoupon?.start_date);
      setEndDate(editcoupon?.end_date);
      setAmount(editcoupon?.amount);
      setPercentage(editcoupon?.percentage);

      // setUserLimit(editcoupon?.usage_limit_per_user);
    },
    [editcoupon]
  );

  const handelUpdateMessage = () => {
    const requestData = {
      id: id,
      code: code,
      description: description,
      deal_type: coupontype,
      amount: amount,
      percentage: persentage,
      start_date: startDate,
      end_date: endDate,
      usage_limit_per_user: userlimit,
    };
    couponupdatesuperadmin(requestData);
    setDisabled(true);
    setIsOpen(true);
  };

  const handelDeleteCoupon = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", editcoupon?.id);
    data.append("code", editcoupon?.code);
    coupondeletesuperadmin(data).then((data) => {
      setDeleteCoupon(data);
      setDeleteModal(true);
      document.getElementById("deletecoupon").style.right = "0px";
    });
    e.preventDefault();
  };
  const deleteModalhandel = () => {
    setDeleteModal(false);
    window.location.reload(false);
  };

  function closeModal() {
    setIsOpen(false);
    window.location.reload(false);
  }

  const hadelClickEdit = () => {
    setDisabled(false);
  };

  const handelDealtype = (e) => {
    const val = e.target.value;
    setCoupontype(val);
    setHideProfile(val);
  };

  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };
  // const pastDateDisable = (menu) => {
  //   var today = new Date().toISOString().split("T")[0];
  //   document.getElementsByName(menu)[0].setAttribute("min", today);
  // };
  return (
    <>
      <div className="col-md-12 d-flex">
        <div className="col-md-6 d-flex">
          <div>
            <span className="Coupone_Creator_Circle">C</span>
          </div>
          <div className="Coupone_Creator_title">
            <h5>{editcoupon?.code}</h5>
            <button
              id=" deletecoupon"
              onClick={() => handelDeleteCoupon("deletecoupon")}
            >
              Delete coupon
            </button>
          </div>
          <div className="Modal_popUp">
            <Modal
              isOpen={deletemodal}
              onRequestClose={deleteModalhandel}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              <span>
                <img src="\Super Admin Images\Ticmark.png" alt="" />
              </span>
              <div>
                <h4>Sucessfully Deleted Data</h4>
              </div>
              <button onClick={deleteModalhandel}>close</button>
            </Modal>
          </div>
        </div>

        <div className="col-md-6">
          <div className="Coupone_Creator_close_Icon">
            <span onClick={() => handelClickClose("couponcreator")}>
              <IoMdCloseCircle />
            </span>
          </div>
        </div>
      </div>
      <p
        style={{
          fontSize: "12px",
          color: " #5a5eee",
          fontWeight: "500",
          borderBottom: "1px Solid #5a5eee",
          width: "100px",
        }}
      >
        Coupon details
      </p>
      <div className="Coupone_Creator_top_form">
        <div className="col-md-12   d-flex">
          <div className="col-md-6">
            <input type="hidden" id="id" name="id" value={id} />
            <div className="Coupone_Creator_dropdown">
              {/* <label>Select Package</label> <br />
              <div className="coupon_Creator_input_form">
                <div
                  id="listdropdown"
                  className="dropdown-check-list"
                  onClick={handleDataChange}
                >
                  <span className="anchor">Select Package</span>
                  <ul id="itemsdropdown" className="items">
                    <li>
                      Pro
                      <input
                        type="checkbox"
                        style={{ float: "right", marginTop: "10px" }}
                      />
                    </li>
                    <li>
                      Pro Plus
                      <input
                        type="checkbox"
                        style={{ float: "right", marginTop: "10px" }}
                      />
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div clasName="col-md-6">
            <label>Amount</label>
            <input
              type="text"
              className="form-control"
              placeholder="120"
              value={amount}
              disabled={disabled}
              name="amount"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div> */}
        </div>
      </div>
      <div className="Coupone_Creator_bottom_form">
        <div clasName="col-md-12">
          <label>Coupon code</label>
          <input
            type="text"
            className="form-control"
            placeholder="SKILl09876"
            value={code}
            name="code"
            disabled={disabled}
            onChange={(e) => setCode(e.target.value)}
          />
          <i>
            <GrFormEdit />
          </i>
          <label>Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
            value={description}
            disabled={disabled}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
          />
        </div>
      </div>
      <div className="Coupone_Creator_bottom_form1">
        <div className="col-md-12   d-flex">
          <div className="col-md-6">
            <div className="Coupone_Creator_dropdown">
              <label>Select coupon type</label> <br />
              <div className="coupon_Creator_input_form ">
                <select
                  value={coupontype}
                  onChange={(e) => handelDealtype(e)}
                  name="coupontype"
                  disabled={disabled}
                  // onclick={dealTypeopen}
                >
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>
            </div>
          </div>
          {hideProfile === "fixed" && (
            <div className="col-md-6" id="1">
              <label>Fixed</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                disabled={disabled}
                // value={amount}
              />
            </div>
          )}
          {hideProfile === "percentage" && (
            <div className="col-md-6" id="2">
              <label>Percentage</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setPercentage(e.target.value)}
                value={persentage}
                disabled={disabled}
                // value={amount}
              />
            </div>
          )}
          {/* <div clasName="col-md-6">
            <label>User limit</label>
            <input
              type="text"
              className="form-control"
              placeholder="10"
              value={userlimit}
              onChange={(e) => setUserLimit(e.target.value)}
            />
          </div> */}
        </div>
      </div>
      <div className="Coupone_Creator_bottom_form2">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <label>Schedule</label>
            <input
              type="date"
              className="form-control"
              placeholder="12/03/2022"
              value={startDate}
              // id="txtDate"
              // name="txtDate"
              // onClick={() => pastDateDisable("txtDate")}
            />
          </div>
          <div className="col-md-6">
            <label style={{ color: "#fff" }}>Schedule</label>
            <input
              type="date"
              className="form-control"
              placeholder="12/03/2022"
              value={endDate}
              // id="disableDate"
              // name="disableDate"
              // onClick={() => pastDateDisable("disableDate")}
            />
          </div>
        </div>
      </div>
      {disabled ? (
        <div className="Coupone_Creator_button">
          <button onClick={hadelClickEdit}>Edit</button>
        </div>
      ) : (
        <div className="Coupone_Creator_button">
          <button onClick={() => handelUpdateMessage()}>Update</button>
        </div>
      )}
      <div className="Modal_popUp">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <span>
            <img src="\Super Admin Images\Ticmark.png" alt="" />
          </span>
          <div>
            <h4>Sucessfully Updated Data</h4>
          </div>
          <button onClick={closeModal}>close</button>
        </Modal>
      </div>
    </>
  );
}

export default CouponCreator;
