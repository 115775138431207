import React, { useEffect, useState } from "react";
import "../Styles/ClassManagement.css";
import "bootstrap/dist/css/bootstrap.css";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import Select from "react-select";
import Pagination from "../Pages/Pagination";

//Country API's
import {
  superAdminviewcountry,
  createCountryData,
  postCountryData,
  deleteCountryData,
} from "../Services";
//ExamBoard Apis
import {
  getExamBoard,
  createExamBoard,
  updateExamBoard,
  deleteExamBoardData,
} from "../Services";
//KeyStage /Level API's
import {
  viewkeystage,
  createkeystage,
  updatekeystage,
  deletekeystageData,
} from "../Services";
//Grade Api's
import { viewGrade, createGrade, updateGrade, deleteGrade } from "../Services";
//Subject Api's
import {
  viewSubject,
  createSubjectData,
  editSubjectData,
  deleteSubjectData,
} from "../Services";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ClassManagement() {
  const [tab, setTab] = useState("opendata");
  const [activeTab, setActiveTab] = useState("country");
  const [counter, setCounter] = useState(0);
  const [viewcountry, setViewCountry] = useState([]);
  const [viewexamboard, setViewExamBoard] = useState([]);
  const [keystage, setKeyStage] = useState([]);
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [id, setId] = useState("");

  //Country Data
  const [countryid, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [filepick, setFilePick] = useState("");
  const [disabledCountry, setDisabledCountry] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [countryDeleted, setCountryDeleted] = useState(false);
  const [countryUpdated, setCountryUpdated] = useState(false);

  // examboard data
  const [examboardid, setExamboardId] = useState("");
  const [picklogo, setPickLogo] = useState("");
  const [examboardname, setExamboardName] = useState("");
  const [examOpen, setExamOpen] = useState(false);
  const [disableExamBoard, setDisableExamBoard] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [examUpdate, setexamUpdate] = useState(false);

  //Key Stage/Level Data
  const [keystageId, setKeystageId] = useState("");
  const [keystagename, setKeyStagename] = useState("");
  const [keystagedescription, setKeyStageDescription] = useState("");
  const [levelOpen, setLevelOpen] = useState(false);
  const [ketstagedelete, setketstagedelete] = useState(false);
  const [disabledKeystage, setDisabledKeystage] = useState(true);
  const [ketstageUpadate, setketstageUpadate] = useState(false);

  //Grade State Data
  const [gradename, setGradeName] = useState("");
  const [gradeyear, setGradeYear] = useState("");
  const [gradeUpdate, setGradeUpdate] = useState(false);
  const [disableGrade, setDisableGrade] = useState(true);
  const [gradeid, setGradeId] = useState("");
  const [gradedelete, setGradeDelete] = useState(false);
  const [gradeedit, setGradeEdit] = useState(false);
  //Subject State Data
  const [subjectid, setSubjectId] = useState("");
  const [subjectname, setSubjectName] = useState("");
  const [subjectlogo, setSubjectLogo] = useState("");
  const [subjectcolor, setSubjectColor] = useState("");
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [subjectUpdate, setSubjectUpdate] = useState(false);
  const [subjectDelete, setSubjectDelete] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState([]);
  const [subjectinternal, setSubjectInternal] = useState("");
  const [keystageValue, setKeyStageValue] = useState([]);
  const [keystageinternal, setKeyStageInternal] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [style, setStyle] = useState("active_button");
  const [currentPage, setCurrentPage] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  //pagination handlers
  const countryPageHandler = (pageNumber) => {
    setCurrentPage(viewcountry.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  const examPageHandler = (pageNumber) => {
    setCurrentPage(viewexamboard.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  const levelPageHandler = (pageNumber) => {
    setCurrentPage(viewexamboard.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  const gradePageHandler = (pageNumber) => {
    setCurrentPage(viewexamboard.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  const subjectPageHandler = (pageNumber) => {
    setCurrentPage(viewexamboard.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  useEffect(() => {
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    getExamBoard().then((data) => {
      setViewExamBoard(data);
    });
    viewkeystage().then((data) => {
      setKeyStage(data);
      const keystagerows = [];
      for (let i = 0; i < data.length; i++) {
        keystagerows.push({ value: data[i].id, label: data[i].name });
      }
      setKeyStageInternal(keystagerows);
    });
    viewGrade().then((data) => {
      setGrade(data);
    });
    viewSubject().then((data) => {
      setSubject(data);
      const subjectrows = [];
      for (let i = 0; i < data.length; i++) {
        subjectrows.push({ value: data[i].id, label: data[i].name });
      }
      setSubjectInternal(subjectrows);
    });
  }, []);
  const subjectValueChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const subjectLevelChange = (e) => {
    setKeyStageValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handelHide = (e) => {
    setTab(e.target.id);
    setActiveTab(e.target.id);
    //setStyle("active_button");
    //setIsActive((current) => !current);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //validate create new country data
  const countryValidate = (values) => {
    const fields = [...values];

    const errors = {};
    if (!fields[0][1]) {
      errors.name = "Country name required!";
    } else {
      errors.name = "";
    }

    if (!fields[1][1]) {
      errors.logo = "Logo required!";
    } else {
      errors.logo = "";
    }
    return errors;
  }

  // Country Tab Data API Methods
  const handelCreateCoupon = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("name", countryName);
    data.append("logo", filepick);
    setFormErrors(countryValidate(data));

    if(formErrors.name === "" && formErrors.logo === "") {
      createCountryData(data);
      setIsOpen(true);
    }
    e.preventDefault();
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setFilePick(base64);
  };
  const countryUpdate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", id);
    data.append("name", countryName);
    filepick && data.append("logo", filepick);
    postCountryData(data)
      .then((responseData) => {
        return responseData?.data;
      })
      .catch((err) => {
        alert(err.message);
        throw err;
      });

    document.getElementById("deletecountry").style.right = "0px";
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    setCountryUpdated(true);
    e.preventDefault();
    // window.location.reload(false);
  };
  const deleteCountry = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", id);
    deleteCountryData(data);
    setCountryDeleted(true);
    document.getElementById("deletecountry").style.right = "0px";
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    e.preventDefault();
  };
  function closeModal(e) {
    setIsOpen(false);
    document.getElementById("createcountry").style.right = "0px";
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    e.preventDefault();
  }
  function updateCountryModal(e) {
    setCountryUpdated(false);
    document.getElementById("deletecountry").style.right = "0px";
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    e.preventDefault();
  }

  const deleteCountryModal = (e) => {
    setCountryDeleted(false);
    document.getElementById("deletecountry").style.right = "0px";
    superAdminviewcountry().then((data) => {
      setViewCountry(data);
    });
    e.preventDefault();
  };

  //validate create new exam board data
  const examBoardValidate = (values) => {
    const fields = [...values];
    const errors = {};

    if (!fields[0][1]) {
      errors.country_id = "Select country!";
    } else {
      errors.country_id = "";
    }

    if (!fields[1][1]) {
      errors.name = "Name required!";
    } else {
      errors.name = "";
    }

    if (!fields[2][1]) {
      errors.logo = "Logo required!";
    } else {
      errors.logo = "";
    }
    return errors;
  }

  // Exam Board Tab Data Api Methods
  const handelCreateExamBoard = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("country_id", id);
    data.append("name", examboardname);
    data.append("logo", picklogo);
    setFormErrors(examBoardValidate(data));

    if(formErrors.country_id === "" && formErrors.logo === "" && formErrors.name === "") {
      createExamBoard(data)
        .then((responseData) => {
          setExamOpen(true);
          return responseData?.data;
        })
        .catch((err) => {
          throw err;
        });
    }
    e.preventDefault();
  };

  const uploadLogo = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setPickLogo(base64);
  };

  const examBoardUpdate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", examboardid);
    data.append("country_id", id);
    data.append("name", examboardname);
    picklogo && data.append("logo", picklogo);
    updateExamBoard(data);
    setexamUpdate(true);
    document.getElementById("deleteexamboard").style.right = "0px";
    getExamBoard().then((data) => {
      setViewExamBoard(data);
    });
    e.preventDefault();
  };
  const examUpdatemodal = (e) => {
    setexamUpdate(false);
    document.getElementById("deleteexamboard").style.right = "0px";
    getExamBoard().then((data) => {
      setViewExamBoard(data);
    });
    e.preventDefault();
  };
  const deleteexamBoard = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", examboardid);
    deleteExamBoardData(data);
    setDeleteModal(true);
    document.getElementById("deleteexamboard").style.right = "0px";
    getExamBoard().then((data) => {
      setViewExamBoard(data);
    });
    e.preventDefault();
  };
  function handelcloseExam() {
    setExamOpen(false);
    document.getElementById("createexamboard").style.right = "0px";
    getExamBoard().then((data) => {
      setViewExamBoard(data);
    });
  }
  const deleteCloseExam = () => {
    setDeleteModal(false);
  };

  //create new level form validation
  const levelValidate = (values) => {
    const fields = [...values];
    const errors = {};

    if (!fields[0][1]) {
      errors.country_id = "Select country!";
    } else {
      errors.country_id = "";
    }

    if (!fields[1][1]) {
      errors.name = "Level Name required!";
    } else {
      errors.name = "";
    }

    if (!fields[2][1]) {
      errors.description = "Description required!";
    } else {
      errors.description = "";
    }
    return errors;
  }

  //Key Stage Onlick Event Create
  const handelKeyStageCreate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("country_id", id);
    data.append("name", keystagename);
    data.append("description", keystagedescription);

    setFormErrors(levelValidate(data));

    if(formErrors.country_id === "" && formErrors.name === "" && formErrors.description === "") {
      createkeystage(data);
      setLevelOpen(true);
    }
    e.preventDefault();
  };

  const editKeyStageUpdate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", keystageId);
    data.append("country_id", id);
    data.append("name", keystagename);
    data.append("description", keystagedescription);
    updatekeystage(data);
    setketstageUpadate(true);
    document.getElementById("deletelevel").style.right = "0px";
    viewkeystage().then((data) => {
      setKeyStage(data);
    });
    e.preventDefault();
  };
  const deleteKeyStage = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", keystageId);
    deletekeystageData(data);
    setketstagedelete(true);
    document.getElementById("deletelevel").style.right = "0px";
    viewkeystage().then((data) => {
      setKeyStage(data);
    });
    e.preventDefault();
  };
  const updatekeystageModal = (e) => {
    setketstageUpadate(false);
    document.getElementById("deletelevel").style.right = "0px";
    viewkeystage().then((data) => {
      setKeyStage(data);
    });
    e.preventDefault();
  };
  const deletekeystageModal = (e) => {
    setketstagedelete(false);
  };

  const subjectLogoPicker = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const base64 = await convertBase64(file);
    setSubjectLogo(base64);
  };

  //create new level form validation
  const gradeValidate = (values) => {
    const fields = [...values];
    const errors = {};

    if (!fields[0][1]) {
      errors.country_id = "Select country!";
    } else {
      errors.country_id = "";
    }

    if (!fields[1][1]) {
      errors.name = "Grade Name required!";
    } else {
      errors.name = "";
    }

    if (!fields[2][1]) {
      errors.year = "Year required!";
    } else {
      errors.year = "";
    }
    return errors;
  }

  //Grade onClick Event Create
  const handelGradeCreate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("country_id", id);
    data.append("name", gradename);
    data.append("year", gradeyear);
    data.append("key_stage_id[0]", keystageValue);

    setFormErrors(gradeValidate(data));

    if (selectedValue[1] > 0) {
      data.append("key_stage_id[1]", keystageValue[1]);
    }
    if (selectedValue[2] > 0) {
      data.append("key_stage_id[2]", keystageValue[2]);
    }
    if (selectedValue[3] > 0) {
      data.append("key_stage_id[3]", keystageValue[3]);
    }
    if (selectedValue[4] > 0) {
      data.append("key_stage_id[4]", keystageValue[4]);
    }
    if (selectedValue[5] > 0) {
      data.append("key_stage_id[5]", keystageValue[5]);
    }
    if (selectedValue[6] > 0) {
      data.append("key_stage_id[6]", keystageValue[6]);
    }
    if (selectedValue[7] > 0) {
      data.append("key_stage_id[7]", keystageValue[7]);
    }
    if (selectedValue[8] > 0) {
      data.append("key_stage_id[8]", keystageValue[8]);
    }
    if (selectedValue[9] > 0) {
      data.append("key_stage_id[9]", keystageValue[9]);
    }
    if (selectedValue[10] > 0) {
      data.append("key_stage_id[10]", keystageValue[10]);
    }
    data.append("subject_id[0]", selectedValue[0]);
    if (selectedValue[1] > 0) {
      data.append("subject_id[1]", selectedValue[1]);
    }
    if (selectedValue[2] > 0) {
      data.append("subject_id[2]", selectedValue[2]);
    }
    if (selectedValue[3] > 0) {
      data.append("subject_id[3]", selectedValue[3]);
    }
    if (selectedValue[4] > 0) {
      data.append("subject_id[4]", selectedValue[4]);
    }
    if (selectedValue[5] > 0) {
      data.append("subject_id[5]", selectedValue[5]);
    }
    if (selectedValue[6] > 0) {
      data.append("subject_id[6]", selectedValue[6]);
    }
    if (selectedValue[7] > 0) {
      data.append("subject_id[7]", selectedValue[7]);
    }
    if (selectedValue[8] > 0) {
      data.append("subject_id[8]", selectedValue[8]);
    }
    if (selectedValue[9] > 0) {
      data.append("subject_id[9]", selectedValue[9]);
    }
    if (selectedValue[10] > 0) {
      data.append("subject_id[10]", selectedValue[10]);
    }

    if(formErrors.country_id === "" && formErrors.name === "" && formErrors.year === "") {
      createGrade(data).then((responseData) => {
        return responseData?.data;
      })
      .catch((err)=>{
        alert(err.message);
        throw err;
      });
      setGradeUpdate(true);
    }
    e.preventDefault();
  };
  const gradeupdateClose = (e) => {
    setGradeUpdate(false);
    document.getElementById("creategrade").style.right = "0px";
    viewGrade().then((data) => {
      setGrade(data);
    });
    e.preventDefault();
  };
  const deleteGradedata = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", gradeid);
    deleteGrade(data);
    setGradeDelete(true);
  };
  const deletegradeModal = (e) => {
    setGradeDelete(false);
    document.getElementById("deletegrade").style.right = "0px";
    viewGrade().then((data) => {
      setGrade(data);
    });
    e.preventDefault();
  };
  const updateGradeModal = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("country_id", id);
    data.append("id", gradeid);
    data.append("name", gradename);
    data.append("year", gradeyear);
    data.append("key_stage_id[0]", keystageValue);
    // if (selectedValue[1] > 0) {
    //   data.append("key_stage_id[9]", keystageValue[1]);
    // }
    data.append("subject_id[0]", selectedValue);
    // if (selectedValue[1] > 0) {
    //   data.append("subject_id[10]", selectedValue[1]);
    // }
    updateGrade(data);
    setGradeEdit(true);
  };
  const editGrade = (e) => {
    setDisableGrade(!disableGrade);
  };
  const editgradeModal = (e) => {
    setGradeEdit(false);
    document.getElementById("deletegrade").style.right = "0px";
    viewGrade().then((data) => {
      setGrade(data);
    });
    e.preventDefault();
  };
  const handleClickAdd = () => {
    setCounter(counter + 1);
    console.log(counter);
  };

  function handelcloseLevel() {
    setLevelOpen(false);
    document.getElementById("createlevel").style.right = "0px";
    viewkeystage().then((data) => {
      setKeyStage(data);
    });
  }

  //create new level form validation
  const subjectValidate = (values) => {
    const fields = [...values];
    const errors = {};

    if (!fields[0][1]) {
      errors.name = "Subject name required!";
    } else {
      errors.name = "";
    }

    if (!fields[1][1]) {
      errors.logo = "Logo required!";
    } else {
      errors.logo = "";
    }
    return errors;
  }

  //Subject Data From Api Methods
  const handelSubjectCreate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("name", subjectname);
    data.append("logo", subjectlogo);
    data.append("color", subjectcolor);

    setFormErrors(subjectValidate(data));

    if(formErrors.name === "" && formErrors.logo === "") {
      createSubjectData(data);
      setSubjectOpen(true);
    }
    e.preventDefault();
  };
  const handelSubjectUpdate = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", subjectid);
    data.append("name", subjectname);
    subjectlogo && data.append("logo", subjectlogo);
    data.append("color", subjectcolor);
    editSubjectData(data)
      .then((responseData) => {
        return responseData?.data;
      })
      .catch((err) => {
        alert(err.message);
        throw err;
      });
    setSubjectUpdate(true);
    document.getElementById("createsubject").style.right = "0px";
    viewSubject().then((data) => {
      setSubject(data);
    });
    e.preventDefault();
  };

  const deleteSubject = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", subjectid);
    deleteSubjectData(data);
    setSubjectDelete(true);
    document.getElementById("createsubject").style.right = "0px";
    e.preventDefault();
  };

  function updateSubjectClose(e) {
    setSubjectUpdate(false);
    document.getElementById("deletesubject").style.right = "0px";
    e.preventDefault();
    viewSubject().then((data) => {
      setSubject(data);
    });
  }

  function handelcloseSubject() {
    setSubjectOpen(false);
    document.getElementById("createsubject").style.right = "0px";
    viewSubject().then((data) => {
      setSubject(data);
    });
  }
  function deleteSubjectModal(e) {
    setSubjectDelete(false);
    document.getElementById("deletesubject").style.right = "0px";

    viewSubject().then((data) => {
      setSubject(data);
    });
    e.preventDefault();
  }

  const handleClickEdit = () => {
    setDisabled(!disabled);
    setSubjectLogo();
  };
  const editCountry = () => {
    setDisabledCountry(!disabledCountry);
    setFilePick();
  };
  const editExamboard = () => {
    setDisableExamBoard(!disableExamBoard);
    setPickLogo();
  };
  const editKeyStage = () => {
    setDisabledKeystage(!disabledKeystage);
  };
  //Sibe Component Open and Close Methods

  const handelClickChange = (menu, edit) => {
    if (menu === "deletecountry") {
      // console.log(edit);
      setId(edit.id);
      setCountryName(edit.name);
      setFilePick(edit.logo);
    }
    if (menu === "deleteexamboard") {
      setExamboardId(edit.id);
      setId(edit.country_id);
      setExamboardName(edit.name);
      setPickLogo(edit.logo);
    }
    if (menu === "deletelevel") {
      setKeystageId(edit.id);
      setId(edit.country_id);
      setKeyStagename(edit.name);
      setKeyStageDescription(edit.description);
    }
    if (menu === "deletesubject") {
      setSubjectId(edit.id);
      setSubjectName(edit.name);
      setSubjectLogo(edit.logo);
      setSubjectColor(edit.color);
    }
    if (menu === "deletegrade") {
      setGradeId(edit.id);
      setGradeName(edit.name);
      setGradeYear(edit.year);

      if (edit.key_stage.length > 0) {
        const keystagerows = [];
        for (let i = 0; i < edit.key_stage.length; i++) {
          keystagerows.push(edit.key_stage[i].id);
        }
        setKeyStageValue(keystagerows);
      }
      if (edit.subjects.length > 0) {
        const subjectrows = [];
        for (let i = 0; i < edit.subjects.length; i++) {
          subjectrows.push(edit.subjects[i].id);
        }

        setSelectedValue(subjectrows);
      }
    }
    if (menu === "creategrade") {
      setKeyStageValue([]);
      setSelectedValue([]);
    }
    document.getElementById(menu).style.right = "550px";
  };

  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };

  return (
    <div className="ClassManagement_wrapper">
      <div className="ClassManagement_buttons d-block">
        <div className="{style} col-md-12 d-md-flex justify-content-md-end">
          <button onClick={handelHide} className={activeTab === 'country' && style} id="opendata" >Country</button>
          <button onClick={handelHide} id="examboard"
              className={activeTab === 'examboard' && style}
          >Exam Board</button>
          <button
            onClick={handelHide}
            //  style={{
            //   backgroundColor: isActive ? "" : "#5a5eee",
            //   color: isActive ? "#111" : "#fff",
            // }}
            id="level"
            className={activeTab === 'level' && style}
          >
            Level
          </button>
          <button onClick={handelHide} id="grade"
            className={activeTab === 'grade' && style}
          >Grade</button>
          <button onClick={handelHide} id="subject"
            className={activeTab === 'subject' && style}
          >Subject</button>
        </div>

        <div className="row">
          {/* Create Country button Body */}
          {tab === "opendata" ? (
            <div id="opendata">
              <div className="ClassManagement_body">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <form className="d-flex Searchbar">
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="col-md-6" style={{marginLeft: '40px'}}>
                    <button onClick={() => handelClickChange("createcountry")}>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create Country
                    </button>
                  </div>
                </div>
                <div>
                  <div className="ClassManagement_Country_table">
                    <table className="table" id="ClassManagement_Country_table">
                      <tr>
                        <th>Country Logo</th>
                        <th>Name</th>
                      </tr>
                      {viewcountry.length > 0 &&
                        viewcountry.map((country, key) => (
                          <tr>
                            <td
                              onClick={() =>
                                handelClickChange("deletecountry", country)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <img src={country?.logo} alt="" />
                              </span>
                            </td>
                            <td>{country?.name}</td>
                          </tr>
                        ))}
                    </table>
                    <Pagination
                      suscriptiondata={viewcountry}
                      pageHandler={countryPageHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Create ExamBoard button Body */}
          {tab === "examboard" ? (
            <div id="examboard">
              <div className="ClassManagement_body">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <form className="d-flex Searchbar">
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="col-md-6 examboard_create_button">
                    <button
                      onClick={() => handelClickChange("createexamboard")}
                    >
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create Examboard
                    </button>
                  </div>
                </div>
                <div>
                  <div className="ClassManagement_Exam_Board_table">
                    <table
                      className="table"
                      id="ClassManagement_Exam_Board_table"
                    >
                      <tr>
                        <th>Logo</th>
                        <th>Country</th>
                        <th style={{ color: "#fff" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                        <th>Exam Board</th>
                        <th>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                      </tr>
                      {viewexamboard.length > 0 &&
                        viewexamboard.map((examboard) => (
                          <tr>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handelClickChange("deleteexamboard", examboard)
                              }
                            >
                              <span>
                                <img src={examboard?.logo} alt="" />
                              </span>
                            </td>
                            <td>
                              &nbsp;&nbsp;&nbsp;&nbsp;{examboard?.country?.name}
                            </td>
                            <td></td>

                            <td> &nbsp;&nbsp;&nbsp;&nbsp;{examboard?.name}</td>
                          </tr>
                        ))}
                    </table>
                    <Pagination
                      suscriptiondata={viewexamboard}
                      pageHandler={examPageHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Create Level button Body */}
          {tab === "level" ? (
            <div id="level">
              <div className="ClassManagement_body">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <form className="d-flex Searchbar">
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="col-md-6 level_create_button">
                    <button onClick={() => handelClickChange("createlevel")}>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create Level
                    </button>
                  </div>
                </div>
                <div>
                  <div className="ClassManagement_Level_table">
                    <table className="table" id="ClassManagement_Level_table">
                      <tr>
                        <th>Country Name</th>
                        <th>Level Name</th>
                        <th>Description</th>
                      </tr>
                      {keystage.length > 0 &&
                        keystage.map((keystage) => (
                          <tr>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handelClickChange("deletelevel", keystage)
                              }
                            >
                              {keystage?.country?.name}
                            </td>
                            <td>{keystage?.name}</td>
                            <td>{keystage?.description}</td>
                          </tr>
                        ))}
                    </table>
                    <Pagination
                      suscriptiondata={keystage}
                      pageHandler={levelPageHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Create Grade Button Body */}
          {tab === "grade" ? (
            <div id="grade">
              <div className="ClassManagement_body">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6 d-flex">
                    <form className="d-flex Searchbar">
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="col-md-6 grade_create_button">
                    <button onClick={() => handelClickChange("creategrade")}>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create Grade
                    </button>
                  </div>
                </div>
                <div>
                  <div className="ClassManagement_Grade_table">
                    <table className="table" id="ClassManagement_Grade_table">
                      <tr>
                        <th>Name</th>
                        <th>Level Name</th>
                        <th>Subject</th>
                        <th>Country</th>
                      </tr>
                      {grade.length > 0 &&
                        grade.map((year) => (
                          <tr>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handelClickChange("deletegrade", year)
                              }
                            >
                              {year.name}&nbsp;{year.year}
                            </td>
                            <td>{year?.key_stage[0]?.name}</td>
                            <td>{year?.subjects[0]?.name}</td>
                            <td>{year?.key_stage[0]?.country?.name}</td>
                          </tr>
                        ))}
                    </table>
                    <Pagination
                      suscriptiondata={grade}
                      pageHandler={gradePageHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Create Subject Button Body */}
          {tab === "subject" ? (
            <div id="subject">
              <div className="ClassManagement_body">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6 d-flex"></div>
                  <div className="col-md-6 subject_create_button">
                    <button onClick={() => handelClickChange("createsubject")}>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create Subject
                    </button>
                  </div>
                </div>
                <div>
                  <div className="ClassManagement_Subject_table">
                    <table className="table" id="ClassManagement_Subject_table">
                      <tr>
                        <th>Logo</th>
                        <th>Subject</th>
                        <th>Color Code</th>
                      </tr>
                      {subject.length > 0 &&
                        subject.map((subject) => (
                          <tr>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handelClickChange("deletesubject", subject)
                              }
                            >
                              <span>
                                <img src={subject?.logo} alt="" />
                              </span>
                            </td>
                            <td>{subject?.name}</td>
                            <td>
                              <button
                                style={{
                                  backgroundColor: subject?.color,
                                  borderColor: subject?.color,
                                  color: "#fff",
                                }}
                              >
                                {subject?.color}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </table>
                    <Pagination
                      suscriptiondata={subject}
                      pageHandler={subjectPageHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Create Country Pannel */}
      <div className="panel-wrap" id="createcountry">
        <div className="panel">
          <div className="col-md-12 ">
            <div className="create-country_pannel d-flex">
              <div className="col-md-6">
                <p>Create New Country</p>
                <div id="horizontal_line">
                  <hr />
                </div>
              </div>
              <div className="col-md-6">
                <i id="close" onClick={() => handelClickClose("createcountry")}>
                  <IoMdCloseCircle />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-7">
              <form className="create-country_form">
                <label for="formFile" className="form-label">
                  Logo
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => {
                    uploadImage(e);
                    setFilePick(e.target.value);
                  }}
                  // onChange={(e) => setFilePick(e.target.value)}
                />
                <span className="class-mgt-error">
                    {formErrors.logo}
                </span> <br />
                {/* {filepick && <ImageThumb image={filepick} />} */}
                <label>Country Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountryName(e.target.value)}
                  name="countryName"
                />
                 <span className="class-mgt-error">
                    {formErrors.name}
                 </span>
              </form>
            </div>
          </div>
          <div className="create-country_form_button">
            <button onClick={handelCreateCoupon}>Save</button>
          </div>
        </div>
        <div className="Modal_popUp">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <span>
              <img src="\Super Admin Images\Ticmark.png" alt="" />
            </span>
            <div>
              <h4>Country Created Sucessfully</h4>
            </div>
            <button onClick={closeModal}>close</button>
          </Modal>
        </div>
      </div>
      {/* {Delete Country Pannel} */}
      <div className="panel-wrap" id="deletecountry">
        <div className="panel">
          <div className="Grade_delete_panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="Coupone_Creator_Circle">
                    {viewcountry[0]?.name.charAt(0)}
                  </span>
                </div>
                <div className="Coupone_Creator_title">
                  <h5>{countryName}</h5>
                  <button onClick={deleteCountry}>Delete Country</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Coupone_Creator_close_Icon">
                  <span onClick={() => handelClickClose("deletecountry")}>
                    <IoMdCloseCircle />
                  </span>
                  &nbsp;
                </div>
                <div className="Modal_popUp">
                  <Modal
                    isOpen={countryDeleted}
                    onRequestClose={deleteCountryModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>Country Data Sucessfully Deleted</h4>
                    </div>
                    <button onClick={deleteCountryModal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <p>Country</p>
            </div>
            <div className="col-md-12">
              <div className="col-md-7">
                <form className="Grade_delete_form">
                  <input type="hidden" id="id" name="id" value={id} />
                  <label>Logo</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Maths"
                    disabled={disabledCountry}
                    //value={filepick}
                    onChange={(e) => uploadImage(e)}
                  />
                  <label>Country Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Loremlipsum.png"
                    disabled={disabledCountry}
                    value={countryName}
                    onChange={(e) => setCountryName(e.target.value)}
                    name="countryName"
                    id={id}
                  />

                  {disabledCountry ? (
                    <div className="Grade_delete_form_button">
                      <button onClick={editCountry}>Edit</button>
                    </div>
                  ) : (
                    <div className="Grade_delete_form_button">
                      <button type="button" onClick={countryUpdate}>
                        Update
                      </button>
                    </div>
                  )}
                </form>
                <div className="Modal_popUp">
                  <Modal
                    isOpen={countryUpdated}
                    onRequestClose={updateCountryModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>Country Data Sucessfully Updates</h4>
                    </div>
                    <button onClick={updateCountryModal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create ExamBoard Pannel */}
      <div className="panel-wrap" id="createexamboard">
        <div className="panel">
          <div className="col-md-12 ">
            <div className="create-exam_board_pannel d-flex">
              <div className="col-md-6">
                <p>Create New Examboard</p>
                <div id="horizontal_line">
                  <hr />
                </div>
              </div>
              <div className="col-md-6">
                <i
                  id="close"
                  onClick={() => handelClickClose("createexamboard")}
                >
                  <IoMdCloseCircle />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-7">
              <form className="create-exam_board_form">
                <label>Country</label>
                <select
                  className="dropdown-toggle"
                  onChange={(e) => setId(e.target.value)}
                  value={id}
                >
                  <option value="">Select Country</option>
                  {viewcountry.length > 0 &&
                    viewcountry.map((country) => (
                      <option value={country.id}>{country.name}</option>
                  ))}
                </select>
                <span className="class-mgt-error">
                    {formErrors.country_id}
                </span><br/>
                <label> Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setExamboardName(e.target.value)}
                />

                <span className="class-mgt-error">
                    {formErrors.name}
                </span> <br/>

                <label for="formFile" className="form-label">
                  Logo
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => {
                    uploadLogo(e);
                  }}
                  // onChange={(e) => setPickLogo(e.target.value)}
                />
                <span className="class-mgt-error">
                    {formErrors.logo}
                </span>
              </form>
            </div>
          </div>
          <div className="create-exam_board_form_button">
            <button onClick={() => handelCreateExamBoard()}>Save</button>
          </div>
          <div className="Modal_popUp">
            <Modal
              isOpen={examOpen}
              onRequestClose={handelcloseExam}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              <span>
                <img src="\Super Admin Images\Ticmark.png" alt="" />
              </span>
              <div>
                <h4>Exam Boadrd Data Created Sucessfully</h4>
              </div>
              <button onClick={handelcloseExam}>close</button>
            </Modal>
          </div>
        </div>
      </div>
      {/* Delete Exam Boad Pannel */}
      <div className="panel-wrap" id="deleteexamboard">
        <div className="panel">
          <div className="Grade_delete_panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="Coupone_Creator_Circle">
                    {viewexamboard[0]?.name.charAt(0)}
                  </span>
                </div>
                <div className="Coupone_Creator_title">
                  <h5>{viewexamboard[0]?.name}</h5>
                  <button onClick={deleteexamBoard}>Delete exam board</button>
                </div>
              </div>
              <div className="Modal_popUp">
                <Modal
                  isOpen={deleteModal}
                  onRequestClose={deleteCloseExam}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                  <span>
                    <img src="\Super Admin Images\Ticmark.png" alt="" />
                  </span>
                  <div>
                    <h4>Exam Boadrd Data Deleted Sucessfully</h4>
                  </div>
                  <button onClick={deleteCloseExam}>close</button>
                </Modal>
              </div>
              <div className="col-md-6">
                <div className="Coupone_Creator_close_Icon">
                  <span onClick={() => handelClickClose("deleteexamboard")}>
                    <IoMdCloseCircle />
                  </span>
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <p>Examboard</p>
            </div>
            <div className="col-md-12">
              <div className="col-md-7">
                <form className="create-exam_board_form">
                  <label>Country</label>
                  <select
                    className="dropdown-toggle"
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    disabled={disableExamBoard}
                  >
                    <option></option>
                    <option>Select Country</option>
                    {viewcountry.length > 0 &&
                      viewcountry.map((country) => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                  </select>
                  <label> Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setExamboardName(e.target.value)}
                    disabled={disableExamBoard}
                    value={examboardname}
                  />
                  <label for="formFile" className="form-label">
                    Logo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    disabled={disableExamBoard}
                    onChange={(e) => {
                      uploadLogo(e);
                    }}
                    // onChange={(e) => setPickLogo(e.target.value)}
                  />
                  {disableExamBoard ? (
                    <div className="Grade_delete_form_button">
                      <button onClick={editExamboard}>Edit</button>
                    </div>
                  ) : (
                    <div className="Grade_delete_form_button">
                      <button type="button" onClick={examBoardUpdate}>
                        Update
                      </button>
                    </div>
                  )}
                </form>
                <div className="Modal_popUp">
                  <Modal
                    isOpen={examUpdate}
                    onRequestClose={examUpdatemodal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>Exam Boadrd Data Updated Sucessfully</h4>
                    </div>
                    <button onClick={examUpdatemodal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create Level Pannel */}
      <div className="panel-wrap" id="createlevel">
        <div className="panel">
          <div className="col-md-12 ">
            <div className="create-Level_pannel d-flex">
              <div className="col-md-6">
                <p>Create New Level</p>
                <div id="horizontal_line">
                  <hr />
                </div>
              </div>
              <div className="col-md-6">
                <i id="close" onClick={() => handelClickClose("createlevel")}>
                  <IoMdCloseCircle />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-7">
              <form className="create-Level_form">
                <label>Contry</label>
                <select
                  className="dropdown-toggle"
                  onChange={(e) => setId(e.target.value)}
                  value={id}
                >
                  <option value="">Select Country</option>
                  {viewcountry.length > 0 &&
                    viewcountry.map((country) => (
                      <option value={country.id}>{country.name}</option>
                    ))}
                </select>
                <span className="class-mgt-error">
                    {formErrors.country_id}
                </span> <br/>
                <label>Level Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Primary / key stage"
                  onChange={(e) => setKeyStagename(e.target.value)}
                />
                <span className="class-mgt-error">
                    {formErrors.name}
                </span> <br/>
                <label for="formFile" className="form-label">
                  Description
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  onChange={(e) => setKeyStageDescription(e.target.value)}
                ></textarea>
                <span className="class-mgt-error">
                    {formErrors.description}
                </span>
              </form>
            </div>
          </div>
          <div className="create-Level_form_button">
            <button onClick={() => handelKeyStageCreate()}>Save</button>
          </div>
          <div className="Modal_popUp">
            <Modal
              isOpen={levelOpen}
              onRequestClose={handelcloseLevel}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              <span>
                <img src="\Super Admin Images\Ticmark.png" alt="" />
              </span>
              <div>
                <h4>Exam Boadrd Data Created Sucessfully</h4>
              </div>
              <button onClick={handelcloseLevel}>close</button>
            </Modal>
          </div>
        </div>
      </div>
      {/* Delete Level Pannel */}
      <div className="panel-wrap" id="deletelevel">
        <div className="panel">
          <div className="Grade_delete_panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="Coupone_Creator_Circle">
                    {keystage[0]?.name.charAt(0)}
                  </span>
                </div>
                <div className="Coupone_Creator_title">
                  <h5>{keystage[0]?.name}</h5>
                  <button onClick={deleteKeyStage}>Delete Level</button>
                </div>
              </div>
              <div className="Modal_popUp">
                <Modal
                  isOpen={ketstagedelete}
                  onRequestClose={deletekeystageModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                  <span>
                    <img src="\Super Admin Images\Ticmark.png" alt="" />
                  </span>
                  <div>
                    <h4>KeyStage Data Deleted Sucessfully</h4>
                  </div>
                  <button onClick={deletekeystageModal}>close</button>
                </Modal>
              </div>
              <div className="col-md-6">
                <div className="Coupone_Creator_close_Icon">
                  <span onClick={() => handelClickClose("deletelevel")}>
                    <IoMdCloseCircle />
                  </span>
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <p>Examboard</p>
            </div>
            <div className="col-md-12">
              <div className="col-md-7">
                <form className="create-Level_form">
                  <label>Contry</label>
                  <select
                    className="dropdown-toggle"
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    disabled={disabledKeystage}
                  >
                    <option></option>
                    <option>Select Country</option>
                    {viewcountry.length > 0 &&
                      viewcountry.map((country) => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                  </select>
                  <label>Level Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Primary / key stage"
                    disabled={disabledKeystage}
                    onChange={(e) => setKeyStagename(e.target.value)}
                    value={keystagename}
                  />
                  <label for="formFile" className="form-label">
                    Description
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    disabled={disabledKeystage}
                    onChange={(e) => setKeyStageDescription(e.target.value)}
                    value={keystagedescription}
                  ></textarea>
                  {disabledKeystage ? (
                    <div className="Grade_delete_form_button">
                      <button onClick={editKeyStage}>Edit</button>
                    </div>
                  ) : (
                    <div className="Grade_delete_form_button">
                      <button type="button" onClick={editKeyStageUpdate}>
                        Update
                      </button>
                    </div>
                  )}
                </form>
                <div className="Modal_popUp">
                  <Modal
                    isOpen={ketstageUpadate}
                    onRequestClose={updatekeystageModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>KeyStage Data Deleted Sucessfully</h4>
                    </div>
                    <button onClick={updatekeystageModal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create Grade Pannel */}
      <div className="panel-wrap_grade" id="creategrade">
        <div className="panel_grade">
          <div className="col-md-12 ">
            <div className="create-Grade_pannel d-flex">
              <div className="col-md-6">
                <p>Create New Grade</p>
                <div id="horizontal_line">
                  <hr />
                </div>
              </div>
              <div className="col-md-6">
                <i id="close" onClick={() => handelClickClose("creategrade")}>
                  <IoMdCloseCircle />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-7">
              <form className="create-Grade_form">
                <label>Contry</label>
                <select onChange={(e) => setId(e.target.value)} value={id}>
                  <option value="">Select Country</option>
                  {viewcountry.length > 0 &&
                    viewcountry.map((country) => (
                      <option value={country.id}>{country.name}</option>
                    ))}
                </select>
                <span className="class-mgt-error">
                    {formErrors.country_id}
                </span> <br/>
                <label>Level Name</label>
                {keystageinternal.length > 0 ? (
                  <Select
                    className="dropdown"
                    placeholder="Select Level"
                    value={keystageinternal.filter((obj) =>
                      keystageValue.includes(obj.value)
                    )} // set selected values
                    options={keystageinternal} // set list of the data
                    onChange={subjectLevelChange} // assign onChange function
                    isMulti
                    isClearable
                  />
                ) : (
                  ""
                )}
                {/* <select onChange={(e) => setKeystageId(e.target.value)}>
                  <option></option>
                  <option>Select Level</option>
                  {keystage.length > 0 &&
                    keystage.map((level) => (
                      <option value={level.id}>{level.name}</option>
                    ))}
                </select> */}

                <label>Subject</label>
                {/* <select onChange={(e) => setSubjectId(e.target.value)}>
                  <option></option>
                  <option>Select Subject</option>
                  {subject.length > 0 &&
                    subject.map((subjectdata) => (
                      <option value={subjectdata.id}>{subjectdata.name}</option>
                    ))}
                </select> */}
                {/* <Multiselect onChange = {(e)=>setSubjectId(e.target.value)}
                  displayValue="key"
                  placeholder="Subject"
                  className=" permission_multiselect_dropdown"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}

                  options={[
                    { key: "Maths" },
                    { key: "Physics" },
                    { key: {subject} },
                    { key: "Biology" },
                  ]}
                  showCheckbox
                /> */}

                {subjectinternal.length > 0 ? (
                  <Select
                    className="dropdown"
                    placeholder="Select Option"
                    value={subjectinternal.filter((obj) =>
                      selectedValue.includes(obj.value)
                    )} // set selected values
                    options={subjectinternal} // set list of the data
                    onChange={subjectValueChange} // assign onChange function
                    isMulti
                    isClearable
                  />
                ) : (
                  ""
                )}
                {/* <div><b>Selected Value: </b> {JSON.stringify(subjectid, null, 2)}</div> */}

                <label>Year</label>
                {/* <select onChange={(e) => setGradeYear(e.target.value)}>
                  <option></option>
                  <option>Select Year</option>
                  {grade.length > 0 &&
                    grade.map((level) => (
                      <option value={level.id}>{level.year}</option>
                    ))}
                </select> */}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setGradeYear(e.target.value)}
                ></input>
                <span className="class-mgt-error">
                    {formErrors.year}
                </span> <br/>
                <label for="formFile" className="form-label">
                  Grade name
                </label>

                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setGradeName(e.target.value)}
                ></input>

                {Array.from(Array(counter)).map((c, index) => {
                  return (
                    <input
                      key={c}
                      type="text"
                      className="form-control"
                      onChange={(e) => setGradeName(e.target.value)}
                    ></input>
                  );
                })}
                <span classname="Icon_circle" onClick={handleClickAdd}>
                  <FiPlusCircle />
                </span> <br/>
                <span className="class-mgt-error">
                    {formErrors.name}
                </span>
              </form>
            </div>
          </div>
          <div className="create-Grade_form_button">
            <button onClick={() => handelGradeCreate()}>Save</button>
          </div>
          <Modal
            isOpen={gradeUpdate}
            onRequestClose={gradeupdateClose}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <span>
              <img src="\Super Admin Images\Ticmark.png" alt="" />
            </span>
            <div>
              <h4>Grade Created Sucessfully</h4>
            </div>
            <button onClick={gradeupdateClose}>close</button>
          </Modal>
        </div>
      </div>
      {/* Delete Grade Pannel */}
      <div className="panel-wrap_grade" id="deletegrade">
        <div className="panel">
          <div className="Grade_delete_panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="Grade_Creator_Circle">
                    {grade[0]?.name.charAt(0)}
                  </span>
                </div>
                <div className="Grade_Creator_title">
                  <h5>{grade[0]?.name}</h5>
                  <button onClick={deleteGradedata}>Delete Grade</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Grade_Creator_close_Icon">
                  <span onClick={() => handelClickClose("deletegrade")}>
                    <IoMdCloseCircle />
                  </span>
                  &nbsp;
                </div>
                <div className="Modal_popUp">
                  <Modal
                    isOpen={gradedelete}
                    onRequestClose={deletegradeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>Grade Data Deleted Sucessfully</h4>
                    </div>
                    <button onClick={deletegradeModal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="col-md-12  GradeTag">
              <p>Grade</p>
            </div>
            {/* <div className="col-md-12">
              <div className="col-md-7">
                <form className="Grade_delete_form">
                  <label>Contry</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="United Kingdom (UK)"
                  />
                  <label>Level Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="key stage1"
                  />
                  <label>Grade Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Grade 1"
                  />
                  <span classname="Icon_circle" onClick={handleClickAdd}>
                    <FiPlusCircle />
                  </span>
                  <div className="Grade_delete_form_button">
                    <button>Edit</button>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="col-md-7">
                <form className="Grade_delete_form">
                  <label>Contry</label>
                  <select
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    disabled={disableGrade}
                  >
                    <option></option>
                    <option>Select Country</option>
                    {viewcountry.length > 0 &&
                      viewcountry.map((country) => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                  </select>

                  <label>Level Name</label>
                  {keystageinternal.length > 0 ? (
                    <Select
                      className="dropdown"
                      placeholder="Select Level"
                      value={keystageinternal.filter((obj) =>
                        keystageValue.includes(obj.value)
                      )} // set selected values
                      disabled={disableGrade}
                      options={keystageinternal} // set list of the data
                      onChange={subjectLevelChange} // assign onChange function
                      isMulti
                      isClearable
                    />
                  ) : (
                    ""
                  )}

                  <label>Subject</label>
                  {subjectinternal.length > 0 ? (
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={subjectinternal.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )} // set selected values
                      disabled={disableGrade}
                      options={subjectinternal} // set list of the data
                      onChange={subjectValueChange} // assign onChange function
                      isMulti
                      isClearable
                    />
                  ) : (
                    ""
                  )}
                  {/* <div><b>Selected Value: </b> {JSON.stringify(subjectid, null, 2)}</div> */}
                  <label>Year</label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    onChange={(e) => setGradeYear(e.target.value)}
                    disabled={disableGrade}
                    value={gradeyear}
                  ></input>

                  <label for="formFile" className="form-label ">
                    Grade name
                  </label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    onChange={(e) => setGradeName(e.target.value)}
                    disabled={disableGrade}
                    value={gradename}
                  ></input>
                  {Array.from(Array(counter)).map((c, index) => {
                    return (
                      <input
                        key={c}
                        type="text"
                        className="form-control"
                        onChange={(e) => setGradeName(e.target.value)}
                        disabled={disableGrade}
                        value={gradename}
                      ></input>
                    );
                  })}
                  <span classname="Icon_circle" onClick={handleClickAdd}>
                    <FiPlusCircle />
                  </span>
                </form>
                {disableGrade ? (
                  <div className="Grade_delete_form_button">
                    <button onClick={editGrade}>Edit</button>
                  </div>
                ) : (
                  <div className="Grade_delete_form_button">
                    <button type="button" onClick={updateGradeModal}>
                      Update
                    </button>
                  </div>
                )}
                <div className="Modal_popUp">
                  <Modal
                    isOpen={gradeedit}
                    onRequestClose={editgradeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                    <span>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </span>
                    <div>
                      <h4>Grade Data Updated Sucessfully</h4>
                    </div>
                    <button onClick={editgradeModal}>close</button>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create Subject Pannel */}
      <div className="panel-wrap" id="createsubject">
        <div className="panel">
          <div className="col-md-12 ">
            <div className="create-exam_board_pannel d-flex">
              <div className="col-md-6">
                <p>Create New Subject</p>
                <div id="horizontal_line">
                  <hr />
                </div>
              </div>
              <div className="col-md-6">
                <i id="close" onClick={() => handelClickClose("createsubject")}>
                  <IoMdCloseCircle />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-7">
              <form className="create-exam_board_form">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSubjectName(e.target.value)}
                />
                <span className="class-mgt-error">
                    {formErrors.name}
                </span> <br/>
                <label for="formFile" className="form-label">
                  Logo
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => subjectLogoPicker(e)}
                />
                <span className="class-mgt-error">
                    {formErrors.logo}
                </span> <br/>
                <label>Subject Color Code</label>
                <input
                  type="color"
                  id="favcolor"
                  className="form-control"
                  onChange={(e) => setSubjectColor(e.target.value)}
                />
              </form>
            </div>
          </div>
          <div className="create-exam_board_form_button">
            <button onClick={() => handelSubjectCreate()}>Save</button>
          </div>
          <Modal
            isOpen={subjectOpen}
            onRequestClose={handelcloseSubject}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <span>
              <img src="\Super Admin Images\Ticmark.png" alt="" />
            </span>
            <div>
              <h4>Subject Created Sucessfully</h4>
            </div>
            <button onClick={handelcloseSubject}>close</button>
          </Modal>
        </div>
      </div>
      {/* Delete Subject Pannel */}
      <div className="panel-wrap" id="deletesubject">
        <div className="panel">
          <div className="Grade_delete_panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="Coupone_Creator_Circle" value={subject.id}>
                    {subject[0]?.name.charAt(0)}
                  </span>
                </div>
                <div className="Coupone_Creator_title">
                  <h5>{subject[0]?.name}</h5>
                  <button onClick={() => deleteSubject()}>
                    Delete Subject
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Coupone_Creator_close_Icon">
                  <span onClick={() => handelClickClose("deletesubject")}>
                    <IoMdCloseCircle />
                  </span>
                  &nbsp;
                </div>
              </div>
              <div className="Modal_popUp">
                <Modal
                  isOpen={subjectDelete}
                  onRequestClose={deleteSubjectModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                  <span>
                    <img src="\Super Admin Images\Ticmark.png" alt="" />
                  </span>
                  <div>
                    <h4>Subject Deleted Sucessfully</h4>
                  </div>
                  <button onClick={deleteSubjectModal}>close</button>
                </Modal>
              </div>
            </div>
            <div className="col-md-12">
              <p>Subject details</p>
            </div>
            <div className="col-md-12">
              <div className="col-md-7">
                <form className="Grade_delete_form">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Maths"
                    disabled={disabled}
                    value={subjectname}
                    onChange={(e) => setSubjectName(e.target.value)}
                  />
                  <label>Logo</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Loremlipsum.png"
                    disabled={disabled}
                    onChange={(e) => subjectLogoPicker(e)}
                    // onChange={(e) => subjectLogoPicker(e)}
                  />
                  <label>Color Code</label>
                  <input
                    type="color"
                    className="form-control"
                    value={subjectcolor}
                    disabled={disabled}
                    onChange={(e) => setSubjectColor(e.target.value)}
                  />
                  {disabled ? (
                    <div className="Grade_delete_form_button">
                      <button onClick={handleClickEdit}>Edit</button>
                    </div>
                  ) : (
                    <div className="Grade_delete_form_button">
                      <button type="button" onClick={handelSubjectUpdate}>
                        Update
                      </button>
                    </div>
                  )}
                </form>
                <Modal
                  isOpen={subjectUpdate}
                  onRequestClose={updateSubjectClose}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                  <span>
                    <img src="\Super Admin Images\Ticmark.png" alt="" />
                  </span>
                  <div>
                    <h4>Subject Updated Sucessfully</h4>
                  </div>
                  <button onClick={updateSubjectClose}>close</button>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassManagement;
