import React from "react";

const Pagination = ({ suscriptiondata, pageHandler }) => {
  let pageNumbers = [];
  for (let i = 1; i < Math.ceil(suscriptiondata.length / 7) + 1; i++) {
    pageNumbers.push(i);
  }
  return (
    <div>
      <center>
        {pageNumbers.map((page) => (
          <div className="pagebutton" onClick={() => pageHandler(page)}>
            {page}
          </div>
        ))}
      </center>
    </div>
  );
};

export default Pagination;
