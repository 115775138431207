import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/MyProfile.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { AiFillEye } from "@react-icons/all-files/ai/AiFillEye";

import Modal from "react-modal";

import {
  superAdminMyProfile,
  superAdminUpdateMyProfile,
  superAdminviewcountry,
} from "../Services";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function MyProfile() {
  const [disabled, setDisabled] = useState(true);

  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [id, setId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [countrylist, setCountryList] = useState("");

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [profile, setProfile] = useState("");

  // const [user, setUser] = useState({
  //   old_password: "",
  //   new_password: "",
  //   confirm_new_password: "",
  //   });

  useEffect(() => {
    superAdminMyProfile().then((data) => {
      setFirstName(data.user?.first_name);
      setLastName(data.user?.last_name);
      setEmail(data.user?.email);
      setPhoneNumber(data.user?.phone);
      setAddress(data.user?.address);
      setCity(data.user?.city);
      setId(data.user?.id);
      setCountry(data.user?.country_code);
      setGender(data.user?.gender);
      setOldPassword(data.user);
      setProfile(data);
    });
    superAdminviewcountry().then((data) => {
      setCountryList(data);
    });
  }, []);

  const updateMessage = (e) => {
    const requestparams = {
      id: id,
      first_name: firstname,
      last_name: lastName,
      email: email,
      address: address,
      phone: phonenumber,
      gender: gender,
      country_code: country,
      city: city,
    };
    superAdminUpdateMyProfile(requestparams);
    setIsOpen(true);
    setDisabled(true);
  };
  const resetPassword = (event) => {
    debugger;
    const requestpassword = {
      first_name: firstname,
      last_name: lastName,
      email: email,
      address: address,
      phone: phonenumber,
      gender: gender,
      country_code: country,
      id: id,
      city: city,
      old_password: oldpassword,
      new_password: newpassword,
      confirm_new_password: confirmpassword,
    };

    superAdminUpdateMyProfile(requestpassword)
      .then((responseData) => {
        // setUser({ ...user, [event.target.name]: event.target.value });
        return responseData?.data;
      })
      .catch((err) => {
        alert(err.message);
        throw err;
      });
  };

  //MyProfile Disable Components
  const handleClick = () => {
    setDisabled(!disabled);
  };
  function closeModal() {
    setIsOpen(false);
  }
  const closedeleteIcon = () => {
    document.getElementById("passwordPanel").style.right = "0px";
  };
  //Right Panel DeleteAccount Event
  const deleteAccount = () => {
    let text = "Are you sure you want to deleteAccount";
    if (window.confirm(text) == true) {
      text = "You pressed OK!";
    } else {
      text = "You canceled!";
    }
  };

  //Right Panel Event
  const handelClickChange = (menu) => {
    document.getElementById(menu).style.right = "550px";
  };
  //Progressbar Event
  const now = 60;
  //Password Reset Form Event
  // const resetPassword = () => {
  //   alert("Sucessfully Created New Password");
  // };

  return (
    <div className="superadmin-account">
      <h1>My profile</h1>
      <div className="superadmin-form-data">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h5>Edit details</h5>
          </div>
          {disabled ? (
            <div className="col-md-6">
              <button onClick={handleClick}>Edit</button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="superadmin-Form">
        <form className="superadmin-Form_wrapper">
          <div className="col-md-12 d-flex">
            <div className="col-md-3">
              <span className="superadmin-myprofile-circle">NM</span>
            </div>
            <div className="col-md-9 d-flex">
              <div className="col-md-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  value={firstname}
                  disabled={disabled}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label>Phone</label>
                <input
                  type="text"
                  name="phonenumber"
                  className="form-control"
                  value={phonenumber}
                  disabled={disabled}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="col-md-3">
                <label>Last name</label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  disabled={disabled}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={email}
                  disabled={disabled}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="col-md-3">
                <label>Gender</label>
                <br />
                <select
                  disabled={disabled}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  name="gender"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <br />
                <label>Password</label>
                <input
                  id="clicker"
                  type="text"
                  placeholder="************"
                  name="password"
                  className="form-control"
                  disabled={disabled}
                  // onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  for="clicker"
                  onClick={() => handelClickChange("passwordPanel")}
                >
                  <FaEdit />
                </i>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <div className="col-md-12">
                <div className="col-md-6">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    value={address}
                    disabled={disabled}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-12 d-flex">
                  <div className="col-md-3">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="London"
                      disabled={disabled}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="col-md-3">
                    <label>Country</label>

                    <br />
                    <select
                      disabled={disabled}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      name="gender"
                      id={country}
                    >
                      {countrylist.length &&
                        countrylist.map((data, index) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex">
            <div className="col-md-3"></div>
            {disabled ? (
              ""
            ) : (
              <div className="col-md-6">
                <button
                  type="button"
                  id="update"
                  onClick={() => updateMessage()}
                >
                  Update
                </button>
                <button id="cancel">Cancel</button>
              </div>
            )}
          </div>
        </form>
        {/* <div className="panel-wrap" id="passwordPanel">
          <div className="panel">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 d-flex">
                <div>
                  <span className="superadmin-password-reset">
                    {profile.user?.first_name.charAt(0)}
                    {profile.user?.first_name.charAt(1)}
                  </span>
                </div>
                <div className="superadmin-password-title">
                  <h5>{profile.user?.first_name}</h5>
                  <button onClick={deleteAccount}>Delete account</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="superadmin-password_close_Icon">
                  <a href="#" onClick={() => closedeleteIcon("passwordPanel")}>
                    <IoMdCloseCircle />
                  </a>
                </div>
              </div>
            </div>
            <div className="Reset_Password_Form">
              <form>
                <div>
                  <label>Current Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Eg: enter current password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    value={oldpassword}
                  />
                  <i>
                    <AiFillEye />
                  </i>
                </div>
                <div>
                  <label>New password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="************"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newpassword}
                  />
                  <i>
                    <AiFillEye />
                  </i>
                </div>
                <div className="ProgressBar">
                  <ProgressBar now={60} label={`${now}%`} />
                </div>
                <br />
                <div>
                  <label>Repeat New password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="*************"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmpassword}
                  />
                  <i>
                    <AiFillEye />
                  </i>
                </div>
                <div>
                  <button onClick={() => resetPassword()}>Update</button>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </div>
      <div className="Modal_popUp">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <span>
            <img src="\Super Admin Images\Ticmark.png" alt="" />
          </span>
          <div>
            <h4>Sucessfully Updated Data</h4>
          </div>
          <button onClick={closeModal}>close</button>
        </Modal>
      </div>
    </div>
  );
}

export default MyProfile;
