import axios from "axios";
import {
  baseURL,
  login,
  myprofile,
  updatemyprofile,
  viewcountry,
  subscription,
  viewsubscription,
  coupon,
  couponupdate,
  couponcreate,
  coupondelete,
  viewalluser,
  viewinviteuser,
  searchCouponBox,
  viewinvitedalluser,
  createcountry,
  editcountry,
  removeCountry,
  getexamboard,
  postexamboard,
  editexamboard,
  removeexamboard,
  getkeystage,
  postkeystage,
  editKeystage,
  removeKeystage,
  getGrade,
  postGrade,
  editGrade,
  removeGrade,
  getSubject,
  postSubject,
  updateSubject,
  deleteSubject,
  getAllUser,
  updateUser,
  getUserPermission,
  viewrolepermission,
  viewRolesList,
  updateUserPermission,
  deleteUserdata,
  editUserdata,
  editUserPermisssion,
  removeUserPermission,
  deleteInvitedUsers,
} from "../Constants";

const headers = {
  Authorization: "Bearer " + sessionStorage.getItem("superAdminToken"), //the token is a variable which holds the token
};
//Signin Page
export const superAdminLogin = async (requestData) => {
  return await axios
    .post(baseURL + login, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Topbar MyProfile page
export const superAdminMyProfile = async (requestData) => {
  return await axios
    .post(baseURL + myprofile, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const superAdminUpdateMyProfile = async (requestData) => {
  return await axios
    .post(baseURL + updatemyprofile, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//List Of Country data
export const superAdminviewcountry = async (requestData) => {
  return await axios
    .post(baseURL + viewcountry, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Financial Management
//Subscription and Payment
export const updatesubsctiption = async (requestData) => {
  //var bodyFormData = new FormData();
  // bodyFormData.append("id", '4');
  // bodyFormData.append("status", 'pending');

  return await axios
    .post(baseURL + subscription, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const viewsubscriptionandpayment = async (requestData) => {
  // var bodyFormData = new FormData();
  // bodyFormData.append("id", '4');

  return await axios
    .post(baseURL + viewsubscription, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Coupon Code API's

//View Coupon API
export const couponviewsuperadmin = async (requestData) => {
  return await axios
    .post(baseURL + coupon, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Create Coupon Api
export const couponcreatesuperadmin = async (requestData) => {
  return await axios
    .post(baseURL + couponcreate, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Update Coupon Api
export const couponupdatesuperadmin = async (requestData) => {
  return await axios
    .post(baseURL + couponupdate, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

//Delete Coupon Api
export const coupondeletesuperadmin = async (requestData) => {
  return await axios
    .post(baseURL + coupondelete, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//View All user API

export const couponviewallusers = async (requestData) => {
  return await axios
    .post(baseURL + viewalluser, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

//View all Invited Users

export const couponviewinviteusers = async (requestData) => {
  return await axios
    .post(baseURL + viewinviteuser, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

//View Invited Users

export const couponviewinvitedallusers = async (requestData) => {
  return await axios
    .post(baseURL + viewinvitedalluser, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const couponinviteddeleteUser = async (requestData) => {
  return await axios
    .post(baseURL + deleteInvitedUsers, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const searchCoupon = async (requestData) => {
  return await axios
    .post(baseURL + searchCouponBox, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
}

//Create Country Data

export const createCountryData = async (requestData) => {
  return await axios
   .post(baseURL +createcountry,requestData,{
    headers,
   })
   .then((responseData) => {
    return responseData?.data;
   })
   .catch((err) => {
    throw err;
   });
};
export const postCountryData = async (requestData) => {
  return await axios
   .post(baseURL +editcountry,requestData,{
    headers,
   })
   .then((responseData) => {
    return responseData?.data;
   })
   .catch((err) => {
    throw err;
   });
};
export const deleteCountryData = async (requestData) => {
  return await axios
   .post(baseURL + removeCountry,requestData,{
    headers,
   })
   .then((responseData) => {
    return responseData?.data;
   })
   .catch((err) => {
    throw err;
   });
};
//Exam Board Body Data

export const getExamBoard = async (requestData) =>{
  return await axios
   .post(baseURL + getexamboard,requestData,{
    headers,
   })
   .then((responseData) => {
    return responseData?.data;
   })
   .catch((err)=>{
    throw err;
   });
};

// ExamBoard Create API Data

export const createExamBoard = async (requestData) => {
  return await axios
    .post (baseURL +  postexamboard, requestData,{
      headers,
    })

}
//Edit and Update Exam Board data
export const updateExamBoard = async (requestData) => {
  return await axios
    .post (baseURL +  editexamboard, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
// Delete Exam Board Data

export const deleteExamBoardData = async (requestData) => {
  return await axios
    .post (baseURL + removeexamboard, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}

// Level(Key Stage) Create Api DATA
export const viewkeystage = async (requestData) => {
  return await axios
    .post (baseURL + getkeystage, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}

export const createkeystage = async (requestData) => {
  return await axios
    .post (baseURL + postkeystage, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
export const updatekeystage = async (requestData) => {
  return await axios
    .post (baseURL + editKeystage, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
export const deletekeystageData = async (requestData) => {
  return await axios
    .post (baseURL + removeKeystage, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
// Grade Api Data
export const viewGrade = async (requestData) => {
  return await axios
    .post (baseURL + getGrade, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
export const createGrade = async (requestData) => {
  return await axios
    .post (baseURL + postGrade, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
export const updateGrade = async (requestData) => {
  return await axios
    .post (baseURL + editGrade, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
export const deleteGrade = async (requestData) => {
  return await axios
    .post (baseURL + removeGrade, requestData,{
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err)=>{
      throw err;
    });
}
// Subject Api Data

export const viewSubject = async (requestData)=>{
  return await axios
  .post (baseURL + getSubject, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const createSubjectData = async (requestData)=>{
  return await axios
  .post (baseURL + postSubject, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const editSubjectData = async (requestData)=>{
  return await axios
  .post (baseURL + updateSubject, requestData,{
    headers,
  })
  .then((responseData) => {
    debugger;
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const deleteSubjectData = async (requestData)=>{
  return await axios
  .post (baseURL +  deleteSubject, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}
// UserManagement API
export const viewallUserData = async (requestData)=>{
  return await axios
  .post (baseURL +  getAllUser, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const createAllUserData = async (requestData)=>{
  return await axios
  .post (baseURL +  updateUser, requestData,{
    headers,
  })

}
export const userRolePermission = async (requestData)=>{
  return await axios
  .post (baseURL +  viewrolepermission, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const viewStaffRoleList = async (requestData)=>{
  return await axios
  .post (baseURL +  viewRolesList, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const viewUserPermissionData = async (requestData)=>{
  return await axios
  .post (baseURL +  getUserPermission, requestData,{
    headers,
  })
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const createUserPermissionData = async (requestData)=>{
  return await axios
  .post (baseURL +  updateUserPermission, requestData,{
    headers,
  })
  // .then((responseData) => {
  //   return responseData?.data;
  // })
  // .catch((err)=>{
  //   throw err;
  // });
}

// Edit And Delete User and UserAccount Permissions

export const updatenewUserData  = async (requestData)=>{
  return await axios
    .post (baseURL + editUserdata, requestData,{
      headers,
    })
      .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const deletenewUserData  = async (requestData)=>{
  return await axios
    .post (baseURL + deleteUserdata, requestData,{
      headers,
    })
      .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const  editUserPermissionData  = async (requestData)=>{
  return await axios
    .post (baseURL + editUserPermisssion, requestData,{
      headers,
    })
      .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}

export const   deleteUserPermissionData = async (requestData)=>{
  return await axios
    .post (baseURL + removeUserPermission, requestData,{
      headers,
    })
      .then((responseData) => {
    return responseData?.data;
  })
  .catch((err)=>{
    throw err;
  });
}
