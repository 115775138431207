import React, { useEffect, useState } from "react";
import "../Styles/UserManagement.css";
import "bootstrap/dist/css/bootstrap.css";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { AiFillEye } from "@react-icons/all-files/ai/AiFillEye";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";

import Modal from "react-modal";

import ProgressBar from "react-bootstrap/ProgressBar";
import { Scrollbars } from "react-custom-scrollbars-2";

import {
  updatenewUserData,
  viewStaffRoleList,
  deletenewUserData,
  viewUserPermissionData,
  editUserPermissionData,
  deleteUserPermissionData,
  superAdminviewcountry,
  getExamBoard,
  viewkeystage,
  viewSubject,
  viewallUserData,
} from "../Services";
const scrollBarStyle = {
  width: "450px",
  height: "150px",
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function UserAccount({ editcoupon }) {
  const [createNewUserTab, setCreateNewUserTab] = useState("newusercreate");
  const [disabled, setDisabled] = useState(true);
  const [id, setId] = useState(editcoupon?.id);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roles, setRoles] = useState("");

  const [countryId, setCountryId] = useState("");
  const [countryInternalId, setCountryInternalId] = useState("");
  const [examboardValue, setExamboardValue] = useState("");
  const [examboardInternal, setExamboardInternal] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [subjectInternal, setSubjectInternal] = useState("");
  const [gradeValue, setGradeValue] = useState("");
  const [gradeInternal, setGradeInternal] = useState("");

  const [CurrentUserId, setCurrentUserId] = useState("");

  const [isActive, setIsActive] = useState(false);

  const [isChecked, setIsChecked] = useState([]);
  const [userPermissions, setUserPermissions] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [deleteUser, setDeleteUser] = useState(false);
  const [userView, setUserView] = useState([]);
  useEffect((e) => {
    viewallUserData().then((data) => {
      setUserView(data?.data);
    });
    viewUserPermissionData().then((data) => {
      setUserPermissions(data);
    });
  }, []);

  useEffect(
    (e) => {
      setId(editcoupon?.id);
      setFirstName(editcoupon?.first_name);
      setLastName(editcoupon?.last_name);
      setEmail(editcoupon?.email);
      setCountryCode(editcoupon?.country_code);
      setPhone(editcoupon?.phone);
      setUserName(editcoupon?.user_name);
      setPassword(editcoupon?.password);
      setRoleId(editcoupon?.role_id);

      setPermissionId(editcoupon?.id);
      setCurrentUserId(editcoupon?.user_id);
      setCountryId(editcoupon?.country_id);
      setExamboardValue(editcoupon?.exam_board_id);
      setSubjectValue(editcoupon?.subject_id);
      setGradeValue(editcoupon?.grade_id);

      if (editcoupon.userPermissions > 0) {
        const permissions = [];
        for (let i = 0; i < editcoupon.userPermissions; i++) {
          permissions.push(editcoupon.userPermissions[i].id);
        }

        setIsChecked(permissions);
      }

      // setIsChecked(editcoupon?.permission[0]);

      viewStaffRoleList().then((data) => {
        setRoles(data);
      });
      superAdminviewcountry().then((data) => {
        setCountryInternalId(data);
      });

      getExamBoard().then((data) => {
        setExamboardInternal(data);
      });
      viewSubject().then((data) => {
        setSubjectInternal(data);
      });
      viewkeystage().then((data) => {
        setGradeInternal(data);
      });
    },
    [editcoupon]
  );

  const handelUpdatePopUp = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", id);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone", phone);
    data.append("country_code", countryCode);
    data.append("password", password);
    data.append("user_name", userName);
    data.append("role_id", roleId);
    updatenewUserData(data)
      .then((responseData) => {
        setCurrentUserId(responseData?.data?.data?.id);
        return responseData?.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updatePermission = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", permissionId);
    data.append("user_id", CurrentUserId);
    data.append("country_id", countryId);
    data.append("exam_board_id", examboardValue);
    data.append("subject_id", subjectValue);
    data.append("grade_id", gradeValue);
    data.append("permission[0]", isChecked);
    editUserPermissionData(data);
  };
  const handleClickEdit = (e) => {
    setDisabled(!disabled);
    e.preventDefault();
  };

  const handelPermissionEdit = (e) => {
    setDisabled(!disabled);
    e.preventDefault();
  };

  const deleteuser = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", id);
    deletenewUserData(data);
    setDeleteUser(true);
    viewallUserData().then((data) => {
      setUserView(data?.data);
    });
    window.location.reload(false);
    document.getElementById("newusercreate").style.right = "0px";
    e.preventDefault();
  };
  const deleteUserAccount = (e) => {
    setDeleteUser(false);
  };

  const deleteAccount = () => {
    let text = "Are you sure you want to deleteAccount";
    if (window.confirm(text) == true) {
      text = "You pressed OK!";
    } else {
      text = "You canceled!";
    }
  };

  //Progressbar Event
  const now = 60;
  const handelUserClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };

  const handelClickChange = (menu) => {
    document.getElementById(menu).style.right = "550px";
  };
  const handelHide = (e) => {
    setCreateNewUserTab(e);
    setIsActive((current) => !current);
  };
  // const handleAddData =(menu) =>{
  //   document.getElementById(menu).style.right = "50px";
  // }
  const handelCheckChange = (e) => {
    const name = parseInt(e.target.value);
    if (e.target.checked) {
      if (!isChecked.includes(name)) {
        isChecked.push(name);
      }
    } else {
      if (isChecked.includes(name)) {
        isChecked.pop(name);
      }
    }
    setIsChecked([...isChecked]);
  };

  return (
    <>
      <div className="UserAccount_wrapper">
        <div className="UserAccount_wrapper_tabset">
          <div className="col-md-12 d-flex">
            <div className="col-md-6 d-flex">
              <div>
                <span className="Coupone_Creator_Circle">
                  {/* {editcoupon?.first_name.charAt(0)} */}J
                </span>
              </div>
              <div className="Coupone_Creator_title">
                <h5>{editcoupon?.first_name}&nbsp;</h5>
                <button onClick={deleteuser}>Delete user</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Coupone_Creator_close_Icon">
                <span onClick={() => handelUserClose("useraccount")}>
                  <IoMdCloseCircle />
                </span>
                &nbsp;
              </div>
            </div>
          </div>
          <div className="Modal_popUp">
            <Modal
              isOpen={deleteUser}
              onRequestClose={deleteUserAccount}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              <span>
                <img src="\Super Admin Images\Ticmark.png" alt="" />
              </span>
              <div>
                <h4>New User Created Sucessfully</h4>
              </div>
              <button onClick={deleteUserAccount}>close</button>
            </Modal>
          </div>
        </div>
        <div className="CreateNewUser_buttons d-block">
          <div className="col-md-12 d-flex">
            <button
              onClick={() => handelHide("newusercreate")}
              style={{
                color: isActive ? "" : "#5a5eee ",
                fontSize: "12px",
              }}
            >
              {" "}
              Account
              <hr
                style={{
                  borderBottom: isActive ? "" : "3px solid #5a5eee",
                  opacity: "100%",
                }}
              />
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              onClick={() => handelHide("permission")}
              style={{
                color: isActive ? "#5a5eee " : "",
                fontSize: "12px",
              }}
            >
              {" "}
              Permission
              <hr
                style={{
                  borderBottom: isActive ? "3px solid #5a5eee " : "",
                  opacity: "100%",
                }}
              />
            </button>
          </div>
          <div className="row">
            {createNewUserTab === "newusercreate" ? (
              <section id="tab-item-1" className="useraccount_tab-content">
                <form className="useraccount_form">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                        name="firstName"
                        value={firstName}
                        disabled={disabled}
                      />

                      <label>Phone Number</label>
                      <div className="d-flex">
                        <div className="col-md-2">
                          <input
                            type="phonenumber"
                            className="form-control"
                            onChange={(e) => setCountryCode(e.target.value)}
                            name="countryCode"
                            value={countryCode}
                            disabled={disabled}
                          />
                        </div>
                        &nbsp;
                        <div className="col-md-10">
                          <input
                            type="phonenumber"
                            className="form-control"
                            onChange={(e) => setPhone(e.target.value)}
                            name="phone"
                            value={phone}
                            disabled={disabled}
                          />
                        </div>
                      </div>

                      <label>Select Role</label>
                      <select
                        onChange={(e) => setRoleId(e.target.value)}
                        id={roleId}
                        disabled={disabled}
                        value={roleId}
                      >
                        <option value="role">Select Role</option>
                        <option>Super Admin</option>
                        {roles.length > 0 &&
                          roles.map((staffrole) => (
                            <option value={staffrole?.id}>
                              {staffrole?.display_name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                        name="lastName"
                        value={lastName}
                        disabled={disabled}
                      />

                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        value={email}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6">
                      <label>User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setUserName(e.target.value)}
                        name="userName"
                        value={userName}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Password</label>
                      <input
                        type="pass"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        value={password}
                        disabled={disabled}
                      />
                      <i
                        for="clicker"
                        onClick={() =>
                          handelClickChange("useraccountpasswordPanel")
                        }
                      >
                        <FaEdit />
                      </i>
                    </div>
                  </div>
                  {disabled ? (
                    <div className="UserAccount_Edit_button">
                      <button onClick={handleClickEdit}>Edit</button>
                    </div>
                  ) : (
                    <div className="UserAccount_Update_button">
                      <button onClick={handelUpdatePopUp}>Update</button>
                    </div>
                  )}
                </form>
                <div
                  className="UserAccount_panel-wrap"
                  id="useraccountpasswordPanel"
                >
                  <div className="UserAccount_panel">
                    <div className="col-md-12 d-flex">
                      <div className="col-md-6 d-flex">
                        <div>
                          <span className="UserAccount-password-reset">MF</span>
                        </div>
                        <div className="UserAccount-password-title">
                          <h5>{editcoupon?.first_name}</h5>
                          <button onClick={deleteAccount}>
                            Delete account
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="UserAccount-password_close_Icon">
                          <span
                            onClick={() =>
                              handelUserClose("useraccountpasswordPanel")
                            }
                          >
                            <IoMdCloseCircle />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="UserAccount_Reset_Password_Form">
                      <form>
                        <div>
                          <label>Current Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Eg: enter current password"
                          />
                          <i>
                            <AiFillEye />
                          </i>
                        </div>
                        <div>
                          <label>New password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="************"
                          />
                          <i>
                            <AiFillEye />
                          </i>
                        </div>
                        <div className="UserAccount_ProgressBar">
                          <ProgressBar now={60} label={`${now}%`} />
                        </div>
                        <br />
                        <div>
                          <label>Repeat New password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*************"
                          />
                          <i>
                            <AiFillEye />
                          </i>
                        </div>
                        <div>
                          <button>Update</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
            {createNewUserTab === "permission" ? (
              <section id="tab-item-2" className="useraccount_tab-content">
                <form className="Permission_tab_form">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6">
                      <select
                        className="dropdown-toggle permission_multiselect_dropdown"
                        onChange={(e) => setCountryId(e.target.value)}
                        // onChange={countryList}
                        placeholder="Select Country"
                        value={countryId}
                      >
                        <option>Select Country</option>
                        {countryInternalId.length > 0 &&
                          countryInternalId.map((country) => (
                            <option value={country.id}>{country.name}</option>
                          ))}
                      </select>
                      <select
                        className="dropdown-toggle permission_multiselect_dropdown"
                        onChange={(e) => setSubjectValue(e.target.value)}
                        // onChange={countryList}
                        placeholder="Subject"
                        value={subjectValue}
                      >
                        <option>Subject</option>
                        {subjectInternal.length > 0 &&
                          subjectInternal.map((subject) => (
                            <option value={subject.id}>{subject.name}</option>
                          ))}
                      </select>
                      &nbsp;&nbsp;
                    </div>
                    <div className="col-md-6">
                      <select
                        className="dropdown-toggle permission_multiselect_dropdown"
                        onChange={(e) => setExamboardValue(e.target.value)}
                        // onChange={countryList}
                        placeholder="Exam board"
                        value={examboardValue}
                      >
                        <option>Exam board</option>
                        {examboardInternal.length > 0 &&
                          examboardInternal.map((examboard) => (
                            <option value={examboard.id}>
                              {examboard.name}
                            </option>
                          ))}
                      </select>
                      <select
                        className="dropdown-toggle permission_multiselect_dropdown"
                        onChange={(e) => setGradeValue(e.target.value)}
                        // onChange={countryList}
                        placeholder="Grade"
                        value={gradeValue}
                      >
                        <option>Grade</option>
                        {gradeInternal.length > 0 &&
                          gradeInternal.map((grade) => (
                            <option value={grade.id}>{grade.name}</option>
                          ))}
                      </select>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Scrollbars
                      vertical
                      autoHide={false}
                      style={scrollBarStyle}
                    >
                      {roles.length > 0 &&
                        roles.map((rolename) => (
                          <div className="Permissions_form-checked-data">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                placeholder="Subject management"
                                value={rolename?.display_name}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <input
                                type="checkbox"
                                onChange={handelCheckChange}
                                checked={isChecked.includes(rolename?.id)}
                                value={rolename?.id}
                              />
                            </div>
                          </div>
                        ))}
                    </Scrollbars>
                  </div>

                  {disabled ? (
                    <div className="UserAccount_Permissions_form_button">
                      <button onClick={handelPermissionEdit}>Edit</button>
                    </div>
                  ) : (
                    <div className="UserAccount_Permissions_form_button">
                      <button onClick={updatePermission}>Save</button>
                    </div>
                  )}
                </form>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAccount;
