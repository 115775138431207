import React, { useEffect, useState } from "react";
import "../Styles/UserManagement.css";
import "bootstrap/dist/css/bootstrap.css";

// import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
// import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";

// import Multiselect from "multiselect-react-dropdown";
import { render } from "react-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
// import Select from "react-select";
// import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";

import {
  createAllUserData,
  viewallUserData,
  viewStaffRoleList,
  superAdminviewcountry,
  getExamBoard,
  viewkeystage,
  viewSubject,
  viewUserPermissionData,
  createUserPermissionData,
} from "../Services";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const scrollBarStyle = {
  width: "450px",
  height: "150px",
};

function CreateNewUser() {
  const [createNewUserTab, setCreateNewUserTab] = useState("newusercreate");
  const [roles, setRoles] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [createNewUserModal, setCreateNewUserModal] = useState(false);
  const [userPermissions, setUserPermissions] = useState("");

  const [countryId, setCountryId] = useState("");
  const [countryInternalId, setCountryInternalId] = useState("");
  const [examboardValue, setExamboardValue] = useState("");
  const [examboardInternal, setExamboardInternal] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [subjectInternal, setSubjectInternal] = useState("");
  const [gradeValue, setGradeValue] = useState("");
  const [gradeInternal, setGradeInternal] = useState("");

  const [CurrentUserId, setCurrentUserId] = useState("");

  const [isActive, setIsActive] = useState(false);

  const [isChecked, setIsChecked] = useState([]);
  const [userView, setUserView] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, seterrorMessage] = useState("");
  const [valid, setValid] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    viewallUserData().then((data) => {
      setUserView(data?.data);
    });
    viewStaffRoleList().then((data) => {
      setRoles(data);
    });
    viewUserPermissionData().then((data) => {
      setUserPermissions(data);
    });
    superAdminviewcountry().then((data) => {
      setCountryInternalId(data);
    });
    // superAdminviewcountry().then((data) => {
    //   const countryData = [];
    //   for (let i = 0; i < data.length; i++) {
    //     countryData.push({ value: data[i].id, label: data[i].name });
    //   }
    //   setCountryInternalId(countryData);
    // });
    getExamBoard().then((data) => {
      setExamboardInternal(data);
      // const examboardData = [];
      // for (let i = 0; i < data.length; i++) {
      //   examboardData.push({ value: data[i].id, label: data[i].name });
      // }
      // setExamboardInternal(examboardData);
    });
    viewSubject().then((data) => {
      setSubjectInternal(data);
      // const subjectData = [];
      // for (let i = 0; i < data.length; i++) {
      //   subjectData.push({ value: data[i].id, label: data[i].name });
      // }
      // setSubjectInternal(subjectData);
    });
    viewkeystage().then((data) => {
      setGradeInternal(data);
      // const keyStageData = [];
      // for (let i = 0; i < data.length; i++) {
      //   keyStageData.push({ value: data[i].id, label: data[i].name });
      // }
      // setGradeInternal(keyStageData);
    });
  }, []);

  // const countryList = (e) => {
  //   setCountryId(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  // const examBoardList = (e) => {
  //   setExamboardValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  // const subjectList = (e) => {
  //   setSubjectValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  // const gradeList = (e) => {
  //   setGradeValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  const validate = (values) => {

    const fields = [...values];

    const errors = {};
    if (!fields[0][1]) {
      errors.firstName = "Firstname required!";
    } else {
      errors.firstName = "";
    }
    if (!fields[1][1]) {
      errors.lastName = "LastName required!";
    } else {
      errors.lastName = "";
    }

    let validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!validEmail.test(fields[2][1])) {
      errors.email = "Email is invalid!";
    }

    if (!fields[2][1]) {
      errors.email = "Email is required!";
    }

    if (!fields[3][1]) {
      errors.countryCode = "Country Code Required!";
    }
    if (!fields[4][1]) {
      errors.phone = "Phone already exists!";
    }
    if (!fields[6][1]) {
      errors.password =
        "Your password contains numbers , symbols and characters";
    } else if (fields[6][1].length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (fields[6][1].length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (!fields[7][1]) {
      errors.roleId = "Please select a role";
    }
    return errors;
  };

  const createNewUser = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("country_code", countryCode);
    data.append("phone", phone);
    data.append("user_name", userName);
    data.append("password", password);
    data.append("role_id", roleId);

    setFormErrors(validate(data));
    createAllUserData(data).then((responseData) => {
        setCurrentUserId(responseData?.data?.data?.id);
        setValid(false);
        document.getElementById("Save_button").disabled = true;
    });

    // .catch((err) => {
    //   seterrorMessage(err?.response?.data?.message);
    //   setValid(true);
    //   throw err;
    // });
    e.preventDefault();
  };
  const addPermissionsUser = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("user_id", CurrentUserId);
    data.append("country_id", countryId);
    data.append("exam_board_id", examboardValue);
    data.append("subject_id", subjectValue);
    data.append("grade_id", gradeValue);
    data.append("permission[0]", isChecked[0]);
    if (isChecked[1] > 0) {
      data.append("permission[1]", isChecked[1]);
    }
    if (isChecked[2] > 0) {
      data.append("permission[2]", isChecked[2]);
    }
    if (isChecked[3] > 0) {
      data.append("permission[3]", isChecked[3]);
    }
    if (isChecked[4] > 0) {
      data.append("permission[4]", isChecked[4]);
    }
    if (isChecked[5] > 0) {
      data.append("permission[5]", isChecked[5]);
    }

    createUserPermissionData(data)
      .then(() => {
        setCreateNewUserModal(true);
        viewallUserData().then((data) => {
          setUserView(data?.data);
        });
        e.preventDefault();
      })
      .catch((err) => {
        throw err;
      });
  };

  const createNewUserClose = (e) => {
    setCreateNewUserModal(false);
    document.getElementById("createnewuser").style.right = "0px";
    viewallUserData().then((data) => {
      setUserView(data?.data);
      window.location.reload(false);
    });
    e.preventDefault();
  };

  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
    document.getElementById("Save_button").disabled = false;

    // Reset error messages
      const errors = {};
      formErrors.firstName = "";
      formErrors.lastName = "";
      formErrors.phone = "";
      formErrors.email = "";
  };
  const handelHide = (e) => {
    setCreateNewUserTab(e);
    setIsActive((current) => !current);
  };
  const handelCheckChange = (e) => {
    const name = parseInt(e.target.value);
    if (e.target.checked) {
      if (!isChecked.includes(name)) {
        isChecked.push(name);
      }
    } else {
      if (isChecked.includes(name)) {
        isChecked.pop(name);
      }
    }
    setIsChecked([...isChecked]);
  };

  return (
    <>
      <div className="CreateNewUser_Wrapper">
        <div className="CreateNewUser_buttons d-block">
          <div className="col-md-12 d-flex">
            <div className="col-md-10">
              <button
                onClick={() => handelHide("newusercreate")}
                style={{
                  color: isActive ? "" : "#5a5eee ",
                }}
              >
                {" "}
                Create New User
                <hr
                  style={{
                    borderBottom: isActive ? "" : " 3px solid #5a5eee",
                    opacity: "100%",
                  }}
                />
              </button>
              &nbsp; &nbsp; &nbsp;
              <button
                onClick={() => handelHide("permission")}
                style={{
                  color: isActive ? "#5a5eee " : "",
                }}
              >
                {" "}
                Add permission
                <hr
                  style={{
                    borderBottom: isActive ? "3px solid #5a5eee " : "",
                    opacity: "100%",
                  }}
                />
              </button>
            </div>
            <div className="col-md-2">
              <span
                id="userClose_Icon"
                onClick={() => handelClickClose("createnewuser")}
              >
                <IoMdCloseCircle />
              </span>
            </div>
          </div>
          <hr />
          <div className="row">
            {/* Create New User Tab Pannel */}
            {createNewUserTab === "newusercreate" ? (
              <section className="tab-panel" id="newusercreate">
                <form>
                  <div className="UserMangement-create-form">
                    <div className="col-md-12">
                      <div className="col-md-12 d-flex">
                        <div className="col-md-6">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setFirstName(e.target.value)}
                            name="firstName"
                            value={firstName}
                            required
                          />
                          <span className="error-msg">
                              {formErrors.firstName}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setLastName(e.target.value)}
                            name="lastName"
                            value={lastName}
                            required
                          />
                          <span className="error-msg">
                            {formErrors.lastName}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-12 d-flex">
                        <div className="col-md-6">
                          <label>Phone Number</label>
                          <div className="d-flex">
                            <div className="col-md-2">
                              <input
                                type="phonenumber"
                                className="form-control"
                                onChange={(e) => setCountryCode(e.target.value)}
                                name="countryCode"
                                value={countryCode}
                                required
                                style={ formErrors.countryCode && {border: '1px solid red'}}
                              />
                            </div>
                            &nbsp;&nbsp;
                            <div className="col-md-10">
                              <input
                                type="phonenumber"
                                className="form-control"
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone"
                                value={phone}
                                style={{ width: "83%" }}
                                required
                              />
                              <span className="error-msg">
                                {formErrors.phone}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            value={email}
                            required
                          />
                          <span className="error-msg">{formErrors.email}</span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-6 d-block">
                          <label>Select Role</label>
                          <select
                            onChange={(e) => setRoleId(e.target.value)}
                            id={roleId}
                            required
                          >
                            <option value="role">Select Role</option>
                            <option>Super Admin</option>
                            {roles.length > 0 &&
                              roles.map((staffrole) => (
                                <option value={staffrole?.id}>
                                  {staffrole?.display_name}
                                </option>
                              ))}
                          </select>
                          <span className="error-msg">{formErrors.roleId}</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex">
                        <div className="col-md-6">
                          <label>User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setUserName(e.target.value)}
                            name="userName"
                            value={userName}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Password</label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            value={password}
                            required
                          />
                          <span className="error-msg">
                            {formErrors.password}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 UserMangement_button d-flex">
                      <div>
                        <button id="Save_button" onClick={createNewUser}>
                          Save
                        </button>
                      </div>
                      <div id="Next_button">
                        <button onClick={() => handelHide("permission")}>
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            ) : (
              ""
            )}
            {/* Add Permission Section Panel */}
            {createNewUserTab === "permission" ? (
              <section className="tab-panel">
                {CurrentUserId > 0 ? (
                  <div className="Permissions_form_data">
                    <div className="col-md-12">
                      <div className="col-md-12 d-flex">
                        <div className="col-md-6">
                          <select
                            className="dropdown-toggle permission_multiselect_dropdown"
                            onChange={(e) => setCountryId(e.target.value)}
                            // onChange={countryList}
                            placeholder="Select Country"
                            value={countryId}
                          >
                            <option>Select Country</option>
                            {countryInternalId.length > 0 &&
                              countryInternalId.map((country) => (
                                <option value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                          {/* {countryInternalId.length > 0 ? (
                            <Multiselect
                              displayValue="label"
                              placeholder="Select Country"
                              className="permission_multiselect_dropdown"
                              value={countryInternalId.filter((obj) =>
                                countryId.includes(obj.value)
                              )}
                              options={countryInternalId}
                              onChange={countryList}
                              showCheckbox
                            />
                          ) : (
                            ""
                          )} */}
                          {/* {countryInternalId.length > 0 ? (
                            <Select
                              className="permission_multiselect_dropdown"
                              placeholder="Select Country"
                              value={countryInternalId.filter((obj) =>
                                countryId.includes(obj.value)
                              )}
                              options={countryInternalId}
                              onChange={countryList}
                              isMulti
                              isClearable
                            />
                            ) : (
                              ""
                            )} */}
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className="col-md-6">
                          <select
                            className="dropdown-toggle permission_multiselect_dropdown"
                            onChange={(e) => setExamboardValue(e.target.value)}
                            // onChange={countryList}
                            placeholder="Exam board"
                            value={examboardValue}
                          >
                            <option>Exam board</option>
                            {examboardInternal.length > 0 &&
                              examboardInternal.map((examboard) => (
                                <option value={examboard.id}>
                                  {examboard.name}
                                </option>
                              ))}
                          </select>
                          {/* {examboardInternal.length > 0 ? (
                          <Multiselect
                            displayValue="label"
                            placeholder="Exam board"
                            className="permission_multiselect_dropdown"
                            value={examboardInternal.filter((obj) =>
                              examboardValue.includes(obj.value)
                            )}
                            options={examboardInternal}
                            onChange={examBoardList}
                            showCheckbox
                          />
                        ) : (
                          ""
                        )} */}
                        </div>
                      </div>
                      <div className="col-md-12 d-flex">
                        <div className="col-md-6">
                          <select
                            className="dropdown-toggle permission_multiselect_dropdown"
                            onChange={(e) => setSubjectValue(e.target.value)}
                            // onChange={countryList}
                            placeholder="Subject"
                            value={subjectValue}
                          >
                            <option>Subject</option>
                            {subjectInternal.length > 0 &&
                              subjectInternal.map((subject) => (
                                <option value={subject.id}>
                                  {subject.name}
                                </option>
                              ))}
                          </select>
                          {/* {subjectInternal.length > 0 ? (
                          <Multiselect
                            displayValue="label"
                            placeholder="Subject"
                            className="permission_multiselect_dropdown"
                            value={subjectInternal.filter((obj) =>
                              subjectValue.includes(obj.value)
                            )}
                            options={subjectInternal}
                            onChange={subjectList}
                            showCheckbox
                          />
                        ) : (
                          ""
                        )} */}
                          {/* <i id="permission_icon1">
             <FiPlusCircle />
           </i> */}
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className="col-md-6">
                          <select
                            className="dropdown-toggle permission_multiselect_dropdown"
                            onChange={(e) => setGradeValue(e.target.value)}
                            // onChange={countryList}
                            placeholder="Grade"
                            value={gradeValue}
                          >
                            <option>Grade</option>
                            {gradeInternal.length > 0 &&
                              gradeInternal.map((grade) => (
                                <option value={grade.id}>{grade.name}</option>
                              ))}
                          </select>
                          {/* {gradeInternal.length > 0 ? (
                          <Multiselect
                            displayValue="label"
                            placeholder="Grade"
                            className="permission_multiselect_dropdown"
                            value={gradeInternal.filter((obj) =>
                              gradeValue.includes(obj.value)
                            )}
                            options={gradeInternal}
                            onChange={gradeList}
                            showCheckbox
                          />
                        ) : (
                          ""
                        )} */}
                          {/* <i id="permission_icon2">
             <FiPlusCircle />
           </i> */}
                        </div>
                      </div>
                    </div>
                    <span className="horizontal_Line">
                      <hr />
                    </span>
                    <div className="col-md-12">
                      <Scrollbars
                        vertical
                        autoHide={false}
                        style={scrollBarStyle}
                      >
                        {roles.length > 0 &&
                          roles.map((rolename) => (
                            <div className="Permissions_form-checked-data  d-block">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  placeholder="Subject management"
                                  value={rolename?.display_name}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                  type="checkbox"
                                  onChange={handelCheckChange}
                                  checked={isChecked.includes(rolename?.id)}
                                  value={rolename?.id}
                                />
                              </div>
                            </div>
                          ))}
                      </Scrollbars>
                      {/* <p>Some great content...</p> */}
                    </div>
                    <div className="Permissions_form_button">
                      <button onClick={addPermissionsUser}>Save</button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </section>
            ) : (
              ""
            )}
          </div>
          <div className="Modal_popUp">
            <Modal
              isOpen={createNewUserModal}
              onRequestClose={createNewUserClose}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              <span>
                <img src="\Super Admin Images\Ticmark.png" alt="" />
              </span>
              <div>
                <h4>New User Created Sucessfully</h4>
              </div>
              <button onClick={createNewUserClose}>close</button>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewUser;
