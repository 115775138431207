import React from 'react'

function QandA() {
  return (
    <div className="dashboard">
        <h1>QandA</h1>
    </div>
  )
}

export default QandA