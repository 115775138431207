import React, { useState, useEffect, useMemo } from "react";
import "../Styles/UserManagement.css";
import "bootstrap/dist/css/bootstrap.css";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import CreateNewUser from "../UserManagement/CreateNewUser";
import UserAccount from "../UserManagement/UserAccount";
// import Pagination from "../Pages/Paginations";

import { viewallUserData, userRolePermission } from "../Services";
import Pagination from "../Pages/Pagination";

let PageSize = 10;

function Users() {
  // const [requestData, setRequestData] = useState(initialValues);
  const [userView, setUserView] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [userRoleInternal, setUserRoleInternal] = useState([]);
  const [editcoupon, setEditCoupon] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);

  // const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return userView.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  useEffect(() => {
    viewallUserData().then((data) => {
      setUserView(data?.data);
    });
    userRolePermission().then((data) => {
      setUserRole(data);
      // const userroles = [];
      // for (let i = 0; i < data.length; i++) {
      //   userroles.push({ value: data[i].id, label: data[i].name });
      // }

      // setUserRole(userroles);
    });
  }, []);

  const userPermission = (e, d) => {
    setUserRoleInternal(Array.isArray(e) ? e.map((x) => x.value) : []);

    var userViews = [];
    var user_permission_role = [];
    var main_permission = [];

    userView.forEach((element) => {
      if (d.id == element.id) {
        main_permission.push({ id: 2 });
        user_permission_role.push(main_permission);
        userViews = element;
        //userViews?.user_permission_role?.main_permission.push({id:2});
      }
    });
    setUserView(e.userViews);
  };

  // const handelPermission = (event) => {
  //   var checkList = document.getElementById("list_items");
  //   var items = document.getElementById("permission");
  //   checkList.getElementsByClassName("anchor")[0].onclick = function (evt) {
  //     if (items.classList.contains("visible")) {
  //       items.classList.remove("visible");
  //       items.style.display = "none";
  //     } else {
  //       items.classList.add("visible");
  //       items.style.display = "block";
  //     }
  //   };
  // };

  const handelUserChange = (menu, edit) => {
    document.getElementById(menu).style.right = "550px";
    if (edit) {
      setEditCoupon(edit);
    }
  };
  
  const CheckhandelChange = (e) => {

    let newList = [...isChecked, e.target.value];
    if (isChecked.includes(e.target.value)) {
      newList = newList.filter(item => item !== e.target.value);
    }

    // const name = parseInt(e.target.value);
    // if (e.target.checked) {
    //   if (!isChecked.includes(name)) {
    //     isChecked.push(name);
    //   }
    // } else {
    //   if (isChecked.includes(name)) {
    //     isChecked.pop(name);
    //   }
    // }

    setIsChecked(newList);
  };

  //pagination handlers
  const userPageHandler = (pageNumber) => {
    setCurrentPage(userView.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  return (
    <>
      <div className="UserManagement_wrapper">
        <div className="col-md-12 ">
          <div className="user-data d-flex">
            <div className="col-md-6">
              <h5>Users list</h5>
            </div>
            <div className="col-md-6">
              <button onClick={() => handelUserChange("createnewuser")}>
                <span>
                  <FiPlusCircle />
                </span>
                &nbsp;&nbsp;Create New User
              </button>
            </div>
          </div>
        </div>
        <div className="user-management-wrapper">
          <form className="d-flex Searchbar">
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
            />
          </form>
          <div className="user-table"></div>
          <table className="table" id="user-table">
            <thead>
              <tr>
                <th >Display name</th>
                <th >User name</th>
                <th >Role</th>
                <th style={{textAlign:"left"}}>Permission</th>
              </tr>
            </thead>

            {userView.length > 0 &&
              userView.map((userlist) => (
                <tr>
                  <td
                    className="Coupon_Name"
                    onClick={() => handelUserChange("useraccount", userlist)}
                    style={{textTransform: 'capitalize'}}
                  >
                    {userlist?.first_name}&nbsp;{userlist?.last_name}
                  </td>
                  <td style={{textTransform: 'capitalize'}}>{userlist?.user_name}</td>
                  <td style={{textTransform: 'capitalize'}}>{userlist?.roles[0]?.display_name}</td>
                  <td>
                    {/* {userRole.length > 0 ? (
                      <div className="user_input_form">
                        <Select
                          className="usercodeCreator-check-list dropdown"
                          placeholder="Permission"
                          value={userRole.filter((obj) =>
                            userlist.user_permission_role?.main_permission
                              .map((e) => e.id)
                              .includes(obj.value)
                          )} // set selected values
                          options={userRole} // set list of the data
                          onChange={(e) => userPermission(e, userlist)} // assign onChange function
                          isMulti
                          isClearable
                          defaultChecked={true}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div>
                    {userRole.length > 0 &&
                      userRole.map((userRole) => (
                        <div style={{textAlign:"left"}}>
                          <input
                            type="checkbox"
                            value={userRole?.id}
                            onChange={(e) => CheckhandelChange(e)}
                            //checked={isChecked.includes(userRole?.id)}

                          />{" "}
                          <span>{userRole?.name}</span>
                          {/* <input type="checkbox"/> <span>Commenter</span>
                     <input type="checkbox"/> <span>Editor</span> */}
                        </div>
                      ))}
                      </div>
                  </td>
                </tr>
              ))}
          </table>
          <Pagination
              suscriptiondata={userView}
              pageHandler={userPageHandler}
          />
          {/* <br/>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={userView.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
          <div className="panel-wrap" id="createnewuser">
            <div className="panel">
              <CreateNewUser />
            </div>
          </div>
          <div className="panel-wrap" id="useraccount">
            <div className="panel">
              <UserAccount editcoupon={editcoupon} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
