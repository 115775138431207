import React, { useState, useEffect } from "react";
import "../Styles/FinancialManagement.css";
import "bootstrap/dist/css/bootstrap.css";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
//import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";

import CouponCodeCreator from "../Pages/CouponCodeCreator";
import CouponInvitaion from "../Pages/CouponInvitaion";
import CouponCodeView from "../Pages/CouponCodeView";
import CouponCreator from "../Pages/CouponCreator";

import { couponviewsuperadmin, couponviewinviteusers, searchCoupon } from "../Services";
import Pagination from "../Pages/Pagination";

function CouponCode() {
  const [coupondata, setCouponData] = useState([]);
  const [expiredCoupon, setExpireCoupon] = useState([]);
  const [editcoupon, setEditCoupon] = useState([]);
  const [isActive, setIsActive] = useState('tab1');
  const[couponinviteId,setCouponId] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState([]);

  useEffect(() => {
    couponviewsuperadmin().then((data) => {
      setCouponData(data?.data);
    });
  }, []);

  useEffect((e) => {
    var bodyFormData = new FormData();
    bodyFormData.append("expired", true);
    couponviewsuperadmin(bodyFormData).then((data) => {
      setExpireCoupon(data?.data);
    });
  }, []);

  const handelInvitationOpen = (e) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('coupon_id',couponinviteId);
    setCouponId()
    couponviewinviteusers(data)
  };

  const handelClickChange = (menu, edit) => {
    document.getElementById(menu).style.right = "560px";
    if (edit) {
      setEditCoupon(edit);
    }
  };

  const handelHide = (e) => {
    setIsActive(e.target.id);
  };

  const handleSearchCoupon = async (e) => {
      e.preventDefault();
      var FormData = require('form-data');
      var data = new FormData();
      data.append('search', searchText);

      await searchCoupon(data).then((data) => {
        setCouponData(data?.data);
      });
  };

  //pagination handlers
  const couponPageHandler = (pageNumber) => {
    setCurrentPage(coupondata.slice(pageNumber * 7 - 7, pageNumber * 7));
  };

  return (
    <div className="CouponCode_Wrapper">
      <div className="CouponCodeandPayment">
        <div className="tabset">
          <input
            type="radio"
            name="tabset"
            id="tab1"
            checked={ isActive === 'tab1'}
            onClick={handelHide}
          />
          <label
            for="tab1"
            style={{
              color: isActive === 'tab1' ? "#5a5eee" : " ",
              opacity: "100%"
            }}
          >
            Coupons
          </label>

          <input
            type="radio"
            name="tabset"
            id="tab2"
            checked={ isActive === 'tab2'}
            onClick={handelHide}
          />
          <label
            for="tab2"
            style={{
              color: isActive === 'tab2' ? "#5a5eee" : "",
              opacity: "100%",
            }}
          >
            Expired
          </label>

          <div className="tab-panels">
            <section className="tab-panel">
              <div className="CouponCode_tab1">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <form className="d-flex Searchbar" onSubmit={handleSearchCoupon}>
                      <button className="serch-icon"
                          style={{top: '5px', left: '60px', backgroundColor: 'transparent',
                                  color: 'black', border: 'none', fontWeight: 'bold'}}>
                        <AiOutlineSearch />
                      </button>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </form>
                  </div>
                  <div className="col-md-6">
                    <button onClick={() => handelClickChange("passwordPanel")}>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create coupon
                    </button>
                  </div>
                </div>
                <div className="CouponCode_table">
                  <table className="table" id="CouponCode_table">
                    <tr>
                      <th>Coupon name</th>
                      <th>Coupon type</th>
                      <th>Amount</th>
                      <th>Persentage</th>
                      <th>Status</th>
                      <th>Invite user</th>
                      <th>View invited</th>
                    </tr>
                    {coupondata.length > 0 &&
                      coupondata.map((coupon) => (
                        <tr>
                          <td
                            className="Coupon_Name"
                            onClick={() =>
                              handelClickChange("couponcreator", coupon)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <h5>{coupon?.code}</h5>
                            <span>{coupon?.description}</span>
                          </td>
                          <td>{coupon?.deal_type}</td>
                          <td>{coupon.amount == 'null' ? 0 : coupon.amount}</td>
                          <td>{coupon.percentage}</td>
                          <td>{coupon?.status}</td>
                          <td>
                            <button
                              onClick={() =>
                                handelClickChange("couponInvitation", coupon?.id)
                              }
                            >
                              Invite
                            </button>
                          </td>
                          <td id="view_coupon">
                            <button
                              onClick={() =>
                                handelClickChange("couponcodeview", coupon?.id)
                              }
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                  </table>
                  <Pagination
                      suscriptiondata={coupondata}
                      pageHandler={couponPageHandler}
                  />
                </div>
              </div>
            </section>
            <section className="tab-panel">
              <div className="CouponCode_tab1">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <form className="d-flex Searchbar">
                      <div className="serch-icon">
                        <AiOutlineSearch />
                      </div>
                      <input
                        className="form-control "
                        type="search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="col-md-6">
                    <button>
                      <span>
                        <FiPlusCircle />
                      </span>
                      &nbsp;&nbsp;Create coupon
                    </button>
                  </div>
                </div>
                <div className="CouponCode_table">
                  <table className="table" id="CouponCode_table">
                    <tr>
                      <th>Coupon name</th>
                      <th>Coupon type</th>
                      <th>Amount</th>
                      <th>Persentage</th>
                      <th>Status</th>
                      <th>Invite user</th>
                      <th>View invited</th>
                    </tr>
                    {expiredCoupon.length > 0 &&
                      expiredCoupon.map((coupon) => (
                        <tr>
                          <td className="Coupon_Name">
                            <h5>{coupon.code}</h5>
                            <span>{coupon.description}</span>
                          </td>
                          <td> {coupon.deal_type}</td>
                          <td>{coupon.amount}</td>
                          <td>{coupon.percentage}</td>
                          <td>{coupon.status}</td>
                          <td id="Coupon__disable">
                            <button id={coupon.id}
                              onClick={() => handelInvitationOpen(coupon.id)}
                            >
                              Invite
                            </button>
                          </td>
                          <td id="view_coupon">
                            <button>View</button>
                          </td>
                        </tr>
                      ))}
                  </table>
                  <Pagination
                      suscriptiondata={expiredCoupon}
                      pageHandler={couponPageHandler}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="panel-wrap" id="passwordPanel">
            <div className="panel">
              <CouponCodeCreator />
            </div>
          </div>
          <div className="coupon-wrap" id="couponInvitation">
            <div className="coupon">
              <CouponInvitaion editcoupon={editcoupon} />
            </div>
          </div>
          <div className="coupon-wrap" id="couponcodeview">
            <div className="coupon">
              <CouponCodeView editcoupon={editcoupon}/>
            </div>
          </div>
          <div className="coupon-wrap" id="couponcreator">
            <div className="coupon">
              <CouponCreator editcoupon={editcoupon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponCode;
