import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/FinancialManagement.css";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import {
  couponviewinvitedallusers,
  couponinviteddeleteUser,
} from "../Services";

function CouponCodeView({ editcoupon }) {
  const [viewinvitedusers, setViewInvitedUsers] = useState("");
  // const [couponid, setCouponId] = useState("");
  const [checkinvitedusers, setCheckInvitedUsers] = useState([]);

  useEffect(() => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("coupon_id", editcoupon);

    couponviewinvitedallusers(data).then((data) => {
      setViewInvitedUsers(data?.data);
    });
  }, [editcoupon]);

  function handelcheckAll() {
    var inputs = document.querySelectorAll(".check2");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = true;
    }
  }
  function uncheckAll() {
    var inputs = document.querySelectorAll(".check2");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  }
  window.onload = function () {
    window.addEventListener("load", handelcheckAll, false);
  };

  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };

  const deleteInvetedUsers = (e) => {

    var FormData = require("form-data");
    var data = new FormData();
    data.append("coupon_id", editcoupon);

    for (var i = 0; i < checkinvitedusers.length; i++) {
      var id = "id[" + i + "]";
      data.append(id, checkinvitedusers[i]);
    }

    couponinviteddeleteUser(data)
      .then((responseData) => {
        return responseData?.data;
      })
      .catch((err) => {
        throw err;
    });

  };
  const handelUserdeleteCheck = (e) => {
    //const name = parseInt(e.target.value);
    let deleteList = [...checkinvitedusers, e.target.value];

    if (checkinvitedusers.includes(e.target.value)) {
      deleteList = deleteList.filter(item => item !== e.target.value);
    }

    // if (e.target.checked) {
    //   if (!checkinvitedusers.includes(name)) {
    //     checkinvitedusers.push(name);
    //   }
    // } else {
    //   if (checkinvitedusers[0].includes(name)) {
    //     checkinvitedusers[0].pop(name);
    //   }
    // }
    setCheckInvitedUsers(deleteList);
  };

  return (
    <>
      <div className="CouponCodeView_wrapper">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h5>Invited user</h5>
            <hr />
          </div>
          <div className="col-md-6">
            <div className="CouponCodeView_Icon">
              <a
                href="#"
                onClick={() => handelClickClose("couponcodeview")}
                style={{ color: "#111" }}
              >
                <IoMdCloseCircle />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <form className="d-flex CouponCodeView_Searchbar">
            <div className="CouponCodeView_serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              type="search"
              className="form-control"
              placeholder="User Name"
            />
          </form>
        </div>
        <div className="CouponInvitaion_checkbox_wrapper">
          <form>
            <div className="d-flex">
              <input
                type="button"
                onClick={handelcheckAll}
                className="btn_Select"
                value="Delete All"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="checkbox"
                className="check2"
                onClick={handelcheckAll}
              />
              <input
                type="button"
                onClick={uncheckAll}
                className="btn_reset "
                value="Reset"
              />
            </div>
            {viewinvitedusers.length > 0 &&
              viewinvitedusers.map((inviteduser, key) => (
                <div className="checkbox_wrapper" key={key}>
                  <div className="checkbox">
                    <label>JK</label>
                    <span>{inviteduser?.user.first_name}</span>
                    <input
                      type="checkbox"
                      onChange={(e) => handelUserdeleteCheck(e)}
                      //checked={checkinvitedusers.includes(inviteduser?.id)}
                      value={inviteduser?.id}
                      className="check2"
                    />
                    <i>
                      <IoMdCloseCircle />
                    </i>
                  </div>
                  {/* <div className="checkbox">
                    <label>JK</label>
                    <span>Jones Fernando</span>
                    <input type="checkbox" className="check2" />
                    <i>
                      <IoMdCloseCircle />
                    </i>
                  </div>
                  <div className="checkbox">
                    <label>JK</label>
                    <span>Jones Fernando</span>
                    <input type="checkbox" className="check2" />
                    <i>
                      <IoMdCloseCircle />
                    </i>
                  </div> */}
                </div>
              ))}
          </form>
        </div>
        <div className="CouponInvitaion_button">
          <button onClick={deleteInvetedUsers}>Delete Invitation</button>
        </div>
      </div>
    </>
  );
}

export default CouponCodeView;
