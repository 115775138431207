import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/FinancialManagement.css";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { GrFormEdit } from "@react-icons/all-files/gr/GrFormEdit";
import moment from "moment";

import { couponviewsuperadmin, couponcreatesuperadmin } from "../Services";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function CouponCodeCreator() {
  const [generatecoupon, setGenerateCoupon] = useState("");
  const [description, setDescription] = useState("");
  const [coupontype, setCoupontype] = useState("");
  const [amount, setAmount] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [viewdealtype, setViewdealtype] = useState("");
  const [persentage, setPercentage] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hideProfile, setHideProfile] = useState("");

  useEffect(() => {
    couponviewsuperadmin().then((data) => {
      setViewdealtype(data?.data);
    });
  }, []);

  const handelCreate = (e) => {
    const bodyFormData = require("form-data");
    var data = new bodyFormData();
    data.append("code", generatecoupon);
    data.append("description", description);
    data.append("deal_type", coupontype);
    data.append("amount", amount);
    data.append("percentage", persentage);
    data.append("start_date", startdate);
    data.append("end_date", enddate);
    data.append("status", "active");
    couponcreatesuperadmin(data)
      .then((responseData) => {
        return responseData?.data;
      })
      .catch((err) => {
        alert("Error: " + err.message);
        throw err;
      });
    setIsOpen(true);
    e.preventDefault();
  };

  const handelDealtype = (e) => {
    const val = e.target.value;
    setCoupontype(val);
    setHideProfile(val);
  };

  function closeModal() {
    setIsOpen(false);
    window.location.reload(false);
  }
  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };
  const pastDateDisable = (menu) => {
    var today = new Date().toISOString().split("T")[0];
    document.getElementsByName(menu)[0].setAttribute("min", today);
  };

  return (
    <>
      <div className="CouponCodeCreator_title">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h5>Create New coupon</h5>
            <hr />
          </div>
          <div className="col-md-6">
            <div className="CouponCodeCreator_Icon">
              <span
                id="close"
                onClick={() => handelClickClose("passwordPanel")}
              >
                <IoMdCloseCircle />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="CouponCodeCreator_full_form">
            <label>Coupon code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Generate the code"
              onChange={(e) => setGenerateCoupon(e.target.value)}
              name="generatecoupon"
              // value={generatecoupon}
            />
            <i>
              <GrFormEdit />
            </i>
          </div>
        </div>
        <br />
        <div className="col-md-12">
          <div className="CouponCodeCreator_full_form">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
              // value={description}
            />
          </div>
        </div>
        <br />
        <div className="col-md-12 d-flex">
          <div className="col-md-6 ">
            <div className="CouponCodeCreator_dropdown">
              <label>Select coupon type</label>
            </div>
            <div className="coupon_Creator_input_form ">
              <select
                onChange={(e) => handelDealtype(e)}
                value={coupontype}
                // onclick={dealTypeopen}
              >
                <option value="">Select</option>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </select>
            </div>
          </div>
          {hideProfile === "fixed" && (
            <div className="col-md-6" id="1">
              <label>Fixed</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                // value={amount}
              />
            </div>
          )}
          {hideProfile === "percentage" && (
            <div className="col-md-6" id="2">
              <label>Percentage</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setPercentage(e.target.value)}
                value={persentage}
                // value={amount}
              />
            </div>
          )}
        </div>
        <br />
        <div className="col-md-12 ">
          <div className="CouponCodeCreator_full_form d-flex">
            <div className="col-md-6">
              <label>Schedule</label>
              <input
                type="date"
                className="form-control"
                placeholder="12/03/2022"
                onChange={(e) => setStartDate(e.target.value)}
                id="txtDate"
                name="txtDate"
                onClick={()=>pastDateDisable("txtDate")}
                // value={startdate}
              />
            </div>
            &nbsp;
            <div className="col-md-6">
              <label style={{ color: "#fff" }}>Schedule</label>
              <input
                type="date"
                className="form-control"
                placeholder="12/03/2022"
                onChange={(e) => setEndDate(e.target.value)}
                id="disableDate"
                name="disableDate"
                onClick={()=>pastDateDisable("disableDate")}
                // value={enddate}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="Coupone_Creator_button">
          <button onClick={() => handelCreate()}>Save</button>
        </div>
        <div className="Modal_popUp">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <span>
              <img src="\Super Admin Images\Ticmark.png" alt="" />
            </span>
            <div>
              <h4>Coupon Create Sucessfully</h4>
            </div>
            <button onClick={closeModal}>close</button>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CouponCodeCreator;
