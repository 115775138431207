//export const baseURL = "http://localhost:8000/api/";

//export const base = "http://localhost:8000";

export const baseURL = "https://sa.api.smartht.co.uk/api/";

export const base = "https://sa.api.smartht.co.uk";

//SuperAdmin sign in Page URL

export const login = "auth/login";

//SuperAdmin MyProfile Page URL

export const myprofile = "viewProfile";

export const updatemyprofile = "updateProfile";

// Country Data
export const viewcountry = "viewCountry";

export const editcountry = "updateCountry";

export const createcountry = "createCountry";

export const removeCountry = "deleteCountry";

//FInacial Management Subscription and Payment

export const subscription = "changeSubscription";

export const viewsubscription = "viewSubscription";

//Financial Management Coupon Code URL

export const coupon = "viewCoupon";

export const couponupdate = "updateCoupon";

export const couponcreate = "createCoupon";

export const coupondelete = "deleteCoupon";

export const viewalluser = "allUsers";

export const viewinviteuser = "inviteCoupon";

export const viewinvitedalluser = "viewInvitedUser";

export const deleteInvitedUsers = "deleteInvitedUser";

export const searchCouponBox = "searchCoupon";

//Class Management API Methods

// ClassManagement ExamBoard API Methods

export const getexamboard = "viewExamBoard";

export const postexamboard = "createExamBoard";

export const editexamboard = "updateExamBoard";

export const removeexamboard = "deleteExamBoard";

//Level [Key Stage] API Methods

export const getkeystage = "viewKeyStage";

export const postkeystage = "createKeyStage";

export const editKeystage = "updateKeyStage";

export const removeKeystage = "deleteKeyStage";

//Grade Api Methods

export const getGrade = "viewYear";

export const postGrade = "createYear";

export const editGrade = "updateYear";

export const removeGrade = "deleteYear";

//Subject API Methos

export const getSubject = "viewSubject";

export const postSubject = "createSubject";

export const updateSubject = "updateSubject";

export const deleteSubject = "deleteSubject";

// User Management API Reference

export const getAllUser = "viewUser";

export const updateUser = "createUser";

export const viewrolepermission = "viewMainPermission";

export const viewRolesList = "viewStaffRole";

export const getUserPermission = "viewUserPermission ";

export const updateUserPermission = "createUserPermission";

export const editUserPermisssion = "updateUserPermission";

export const removeUserPermission = "deleteUserPermission";

export const editUserdata = "updateUser";

export const deleteUserdata = "deleteUser";
