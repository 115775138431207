import React, { useEffect, useState } from "react";
import "../Styles/FinancialManagement.css";
import "bootstrap/dist/css/bootstrap.css";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { updatesubsctiption, viewsubscriptionandpayment } from "../Services";
import { Link } from "react-router-dom";
import Pagination from "../Pages/Pagination";
function SubscriptionandPayment() {
  const [suscriptiondata, setSubscriptionData] = useState([]);
  const [hideprofile, setHideProfile] = useState(false);
  const [singlesubscriptiondata, setSingleSubscriptionData] = useState([]);
  const [plantype, setPlantype] = useState("");
  const [noofstudents, setNoOfStudents] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentmethod, setPaymentMethod] = useState("");
  const [expiredata, setExpireDate] = useState("");
  const [paymentdate, setPaymentDate] = useState("");
  const [searchplandata, setSearchPlanData] = useState("");
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState([]);

  useEffect(() => {
    viewsubscriptionandpayment().then((data) => {
      setSubscriptionData(data?.data);
      setCurrentPage(data?.data.slice(0, 7));
    });
  }, []);
  const pageHandler = (pageNumber) => {
    setCurrentPage(suscriptiondata.slice(pageNumber * 7 - 7, pageNumber * 7));
  };
  const handelOpen = (event) => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", event);
    bodyFormData.append("status", "pending");
    updatesubsctiption(bodyFormData).then((data) => {
      setSingleSubscriptionData(data?.data);
      setPlantype(data?.data.plan.name);
      setNoOfStudents(data?.data.plan.no_of_students);
      setExpireDate(data?.data.end_date);
      setAmount(data?.data.price);
      setPaymentDate(data?.data.end_date);
      setHideProfile(true);
    });
  };

  const onSearchSubPlan = (event) => {
    setValue(event.target.value);
    viewsubscriptionandpayment(event.target.value).then((data) => {
      if (event.target.value.trim().length) {
        setSearchPlanData(data?.data);
      } else setSearchPlanData([]);
    });
  };
  const handelLessons = (data) => {
    setValue(data.plan?.name);
    setSearchPlanData([]);
  };

  // console.log("1111", suscriptiondata);

  return (
    <div className="SubscriptionandPayment_wrapper">
      <br />
      <div className="SubscriptionandPayment">
        <form className="d-flex Searchbar">
          <div className="serch-icon">
            <AiOutlineSearch />
          </div>
          <input
            className="form-control "
            type="search"
            aria-label="Search"
            onChange={onSearchSubPlan}
            value={value}
            placeholder="Search Subscription"
          />
          <div
            className="dropdown_data Searchbar"
            style={{ cursor: "pointer" }}
          >
            {searchplandata.length > 0 &&
              searchplandata.slice(0, 3).map((data, index) => (
                <p className="view_p" onClick={() => handelLessons(data)}>
                  {data.plan?.name}
                </p>
            ))}
          </div>
        </form>
        <br />

        <div className="SubscriptionandPayment_table">
          <table className="table" id="SubscriptionandPayment_table">
            <tr>
              <th>User type</th>
              <th>Name</th>
              <th>Plan</th>
              <th>Amount</th>
              <th>Status</th>
              <th></th>
            </tr>
            {suscriptiondata.length > 0 &&
              currentPage.map((subscr) => (
                <tr>
                  <td>{subscr.user?.role[0]?.display_name}</td>
                  <td style={{textTransform: 'capitalize'}}>{subscr.user?.full_name}</td>
                  <td>{subscr.plan?.name}</td>
                  <td>{subscr.price}</td>
                  <td id="SubscriptionandPayment_dropdown">
                    <div className="subscribe-wrapper">
                      <select value={subscr.status}>
                        <option value="active">Active</option>
                        <option value="inactive" id="Inactive">
                          Inactive
                        </option>
                        <option value="pending" id="Inactive">
                          In-Progress
                        </option>
                      </select>
                    </div>
                  </td>
                  <td className="">
                    {/* <a href="/subscriptionviewmore">View more</a> */}
                    <button onClick={() => handelOpen(subscr.id)}>
                      View more
                    </button>
                  </td>
                </tr>
              ))}
          </table>
          <Pagination
            suscriptiondata={suscriptiondata}
            pageHandler={pageHandler}
          />
        </div>
      </div>
      {hideprofile ? (
        <div className="SubscriptionViewmore_card">
          <div className="col-md-12 d-flex">
            <div className="col-md-6 d-flex">
              <div className="SubscriptionViewmore_card_Icon">
                <h5>
                  {singlesubscriptiondata?.user?.full_name.charAt(0)}
                  {/* {singlesubscriptiondata?.user?.last_name.charAt(0)} */}
                </h5>
              </div>
              <div className="SubscriptionViewmore_card_Icon_data">
                {/* {JSON.stringify(suscriptiondata)} */}
                {singlesubscriptiondata != undefined && (
                  <h5>
                    {singlesubscriptiondata?.user?.full_name}
                    {/* &nbsp;
                    {singlesubscriptiondata?.user?.last_name} */}
                  </h5>
                )}

                <p>
                  {singlesubscriptiondata.user?.first_name},&nbsp;&nbsp;&nbsp;
                  <span>{singlesubscriptiondata.status}</span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="SubscriptionViewmore_close_Icon" id="close">
                <a href="/subscription" style={{ color: "#111" }}>
                  <IoMdCloseCircle />
                </a>
              </div>
            </div>
          </div>
          <form className="SubscriptionViewmore-form">
            <div className="col-md-12 d-flex">
              <div className="col-md-3">
                <label>Plan</label>
                <input type="text" className="form-control" value={plantype} />
                <label>Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="$25.00"
                  value={amount}
                />
              </div>
              <div className="col-md-3">
                <label>No of student</label>
                <input
                  type="text"
                  className="form-control"
                  value={noofstudents}
                />

                <label>Payment Method</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Visa"
                />
              </div>
              <div className="col-md-3">
                <label>Expiry date</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="12/03/2022"
                  value={expiredata}
                />
                <label>Date of Payment</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="12/02/2022"
                  value={paymentdate}
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SubscriptionandPayment;
