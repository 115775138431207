import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";

function Dashboard() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var d = new Date();
  var monthName = months[d.getMonth()];
  const current = new Date();
  const date = `${current.getDate()}`;
  const time = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const city = sessionStorage.getItem('city');
  const country = sessionStorage.getItem('country');

  return (
    <div className="dashboard-wrapper">
      <div className="row">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h1>Welcome !</h1>
          </div>
          <div className="col-md-6 d-md-flex justify-content-md-end">
            <div className="col-md-4">
              <div className="date">
                {date}
                <sup>th</sup>&nbsp;
                <sub>{monthName}</sub>
              </div>
            </div>
            <div className="col-md-3 d-md-block justify-content-md-end">
              <h5 id="time">{time}</h5>
              <h5 id="Country">{country === 'null' ? '' : country}</h5>
              <p>{city === 'null' ? '' : city}</p>
            </div>
          </div>
        </div>
      </div><br/>
      <div className="row">
        <div class="col-md-4 col-xl-3">
              <div class="card home-card">
                  <div class="card-block">
                      <h6 class="m-b-20 card-title">School</h6>
                      <h2 class="text-right"><span>121</span></h2>
                      <i class="fa fa-cart-plus f-left">icon</i>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-xl-3">
              <div class="card home-card">
                  <div class="card-block">
                      <h6 class="m-b-20 card-title">Parents</h6>
                      <h2 class="text-right"><span>72</span></h2>
                      <i class="fa fa-cart-plus f-left">icon</i>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-xl-3">
              <div class="card home-card">
                  <div class="card-block">
                      <h6 class="m-b-20 card-title">Student</h6>
                      <h2 class="text-right"><span>150</span></h2>
                      <i class="fa fa-cart-plus f-left">icon</i>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-xl-3">
              <div class="card home-card">
                  <div class="card-block">
                      <h6 class="m-b-20 card-title">Other Users</h6>
                      <h2 class="text-right"><span>120</span></h2>
                      <i class="fa fa-cart-plus f-left">icon</i>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default Dashboard;
