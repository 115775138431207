import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Leftbar Components
import Home from "./Dashboard/Home";
import Dashboard from "./Dashboard/Dashboard";
import SubscriptionandPayment from "./Dashboard/SubscriptionandPayment";
import CouponCode from "./Dashboard/CouponCode";
import Users from "./Dashboard/Users";
import Parents from "./Dashboard/Parents";
import ClassManagement from "./Dashboard/ClassManagement";
import Curriculum from "./Dashboard/Curriculum";
import QandA from "./Dashboard/QandA";
import TermsandConditions from "./Dashboard/TermsandConditions";
import SigninPage from "./Pages/SigninPage";
// Topbar Components

import MyProfile from "./Topbar/MyProfile";
import Notifications from "./Topbar/Notifications";
import SignOut from "./Topbar/SignOut";
//Extra Page Components

const NavRoute = ({ component: Component, ...rest }) => (
  <div>
    <Home />
    <Component />
  </div>
);

function App() {
  return (
    <div className="App">
      {/* <Home/> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SigninPage />} />
          <Route
            path="/dashboard"
            element={<NavRoute component={Dashboard} />}
          />
          <Route
            path="/subscription"
            element={<NavRoute component={SubscriptionandPayment} />}
          />
          <Route path="/coupon" element={<NavRoute component={CouponCode} />} />
          <Route path="/users" element={<NavRoute component={Users} />} />
          <Route path="/parents" element={<NavRoute component={Parents} />} />
          <Route
            path="/classmanagement"
            element={<NavRoute component={ClassManagement} />}
          />
          <Route
            path="/curriculum"
            element={<NavRoute component={Curriculum} />}
          />
          <Route path="/qanda" element={<NavRoute component={QandA} />} />
          <Route
            path="/termsandconditions"
            element={<NavRoute component={TermsandConditions} />}
          />
          <Route
            path="/myprofile"
            element={<NavRoute component={MyProfile} />}
          />
          <Route
            path="/notifications"
            element={<NavRoute component={Notifications} />}
          />
          <Route path="/signout" element={<NavRoute component={SignOut} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
