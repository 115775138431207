import React from 'react';

function TermsandConditions() {
  return (
    <div className="dashboard">
        <h1>TermsandConditions</h1>
    </div>
  )
}

export default TermsandConditions