import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";

import { BiGridAlt } from "react-icons/bi";
import { GiDiscGolfBag } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { BsBook } from "react-icons/bs";
import { FaBars, FaRegUser, FaSignOutAlt } from "react-icons/fa";

import { superAdminMyProfile } from "../Services";
import { Link } from "react-router-dom";

function Home() {
  // const user = JSON.parse(sessionStorage.getItem("user"));
  // console.warn(user);

  const [openslider, setOpenSlider] = useState(true);
  const [username, setUsername] = useState([]);

  useEffect(() => {
    superAdminMyProfile().then((data) => {
      setUsername(data);
    });
  }, []);

  const openSubmenu = (menu) => {
    if (
      document.getElementById(menu).style.display === "" ||
      document.getElementById(menu).style.display === "none"
    ) {
      document.getElementById(menu).style.display = "block";
    } else {
      document.getElementById(menu).style.display = "none";
    }
  };

  const handleOpen = () => {
    // let arrow = document.querySelectorAll(".arrow");
    // for (var i = 0; i < arrow.length; i++) {
    //   arrow[i].addEventListener("click", (e) => {
    //     let arrowParent = e.target.parentElement.parentElement;
    //     arrowParent.classList.toggle("showMenu");
    //   });
    // }
    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector(".bx-menu");
    sidebarBtn.addEventListener("click", () => {
      sidebar.classList.toggle("close");
    });
    setOpenSlider(!openslider);
  };

  const [activeTab, setActiveTab] = useState('dashboard');

  const handleClick = (e) => {
     setActiveTab(e.target.id);
  };

  return (
    <div>
      <>
        <div className="sidebar open">
          <div className="logo-details">
            <span className="logo_name">SqillUp</span>
          </div>
          <ul className="nav-links">
            <li className={activeTab === 'dashboard' && 'active'}>
              <Link to="/dashboard">
                <i className="bx bx-grid-alt">
                  {" "}
                  <BiGridAlt />
                </i>
                <span className="link_name" id="dashboard" onClick={(e)=>handleClick(e)}>
                  Dashboard
                </span>
              </Link>
              {/* <ul className="sub-menu blank">
                <li>
                  <Link className="link_name" to="/dashboard">
                    Dashboard
                  </Link>
                </li>
              </ul> */}
            </li>
            <li className={activeTab === 'finance' && 'active'}>
              <div
                className="iocn-link"
                onClick={() => openSubmenu("category-list")}
              >
                <Link to="#">
                  <i className="bx bx-collection">
                    <GiDiscGolfBag />
                  </i>
                  <span className="link_name">Financial Management</span>
                </Link>
                <i className="bx bxs-chevron-down arrow">
                  <MdKeyboardArrowDown />
                </i>
              </div>
              <ul className="sub-menu" id="category-list">
                <li>
                  <Link to="/subscription" id="finance" onClick={(e)=>handleClick(e)}>
                    Subsription and paymet
                  </Link>
                </li>
                <li>
                  <Link to="/coupon" id="finance" onClick={(e)=>handleClick(e)}>
                    Coupon code
                  </Link>
                </li>
              </ul>
            </li>
            <li className={activeTab === 'classmgt' && 'active'}>
              <Link to="/classmanagement">
                <i className="bx bx-line-chart">
                  {" "}
                  <BsBook />
                </i>
                <span className="link_name" onClick={handleClick} id="classmgt">
                  Class Management
                </span>
              </Link>
            </li>
            <li className={activeTab === 'usermgt' && 'active'}>
              <div
                className="iocn-link"
                onClick={() => openSubmenu("user-management")}
              >
                <Link to="#">
                  <i className="bx bx-book-alt">
                    {" "}
                    <FiUsers />
                  </i>
                  <span className="link_name">User Management</span>
                </Link>
                <i className="bx bxs-chevron-down arrow">
                  {" "}
                  <MdKeyboardArrowDown />
                </i>
              </div>
              <ul className="sub-menu" id="user-management">
                <li>
                  <Link to="#" className="link_name">
                    User Management
                  </Link>
                </li>
                <li>
                  <Link to="/users" onClick={handleClick} id="usermgt">Users</Link>
                </li>
                <li>
                  <Link to="/parents" onClick={handleClick} id="usermgt">Parents</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <a href="/curriculum">
                <i className="bx bx-line-chart">
                  {" "}
                  <BsBook />
                </i>
                <span className="link_name">Curriculum</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/curriculum">
                    Curriculum
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a href="/qanda">
                <i className="bx bx-line-chart">
                  {" "}
                  <BsBook />
                </i>
                <span className="link_name">Q&A</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/qanda">
                    Q&A
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a href="/termsandconditions">
                <i className="bx bx-line-chart">
                  {" "}
                  <BsBook />
                </i>
                <span className="link_name">Term & conditions</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/termsandconditions">
                    Term & conditions
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <section className="home-section">
          <div className="home-content">
            <i className="bx bx-menu" onClick={handleOpen}>
              <FaBars />
            </i>

            <div className="Topbar_Right_Side d-flex">
              {/* Notification Bell Icon Div */}
              <div className="notification ">
                <a href="/notifications">
                  {" "}
                  <img src="./Super Admin Images/Notifications.svg" alt="" />
                </a>
              </div>
              {/* My Profile Property Div */}
              <div className="row d-flex">
                <div className="My-Profile-Content">
                  <ul className="nav-links">
                    <li>
                      <div
                        className="iocn-link d-block"
                        onClick={() => openSubmenu("myprofile")}
                      >
                        <div className="My_Profile_Icon">
                          <img
                            src="./Super Admin Images/My Profile.png"
                            alt=""
                          />
                          {/* <img src={username.user?.image} alt="" /> */}
                          <span>
                            <span className="Profile_link_name">
                              Hello,&nbsp;<b>{username.user?.first_name}</b>
                            </span>

                            <br />
                            <span className="Profile_content_name">
                              &nbsp;&nbsp;&nbsp;Super Admin
                            </span>
                          </span>
                          <i
                            className="bx bxs-chevron-down arrow"
                            style={{ cursor: "pointer" }}
                          >
                            <MdKeyboardArrowDown />
                          </i>
                        </div>
                      </div>
                      <ul className="sub-menu " id="myprofile">
                        <li>
                          <i className="bx bx-menu">
                            <FaRegUser />
                          </i>
                          <a href="/myprofile">My Profile</a>
                        </li>

                        <li>
                          <i className="bx bx-menu">
                            <FaSignOutAlt />
                          </i>
                          <a href="/signout">Sign Out</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default Home;
