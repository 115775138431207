import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Signin.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { SpinnerCircular } from "spinners-react";
import { superAdminLogin } from "../Services";

function SigninPage() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  // const [passwordType, setPasswordType] = useState("password");
  const [errorMessage, seterrorMessage] = useState("");

  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.userName = "invalid Login details!";
    }
    else if (!values.password) {
      errors.password =
        "Your password contains numbers , symbols and characters";
    }
    else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }
    else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  const adminhandelLogin = () => {
    const initialValues = {
      email: userName,
      password: password,
    };
    setFormErrors(validate(initialValues));
    superAdminLogin(initialValues)
      .then((data) => {
        setValid(false);

        sessionStorage.setItem("superAdminToken", data.token);
        sessionStorage.setItem("loginUser", data.user?.id);
        sessionStorage.setItem("city", data.user?.city);
        sessionStorage.setItem("country", data.country);

        navigate("/dashboard");
      })
      .catch((error) => {
        seterrorMessage(error?.response?.data?.message);
        setValid(true);
      });
  };

  return (
    <>
      <div className="flex-container">
        <div className="row main-banner">
          <div className="col-md-6 top-p">
            <div className="login-banner">
              <img src="\Super Admin Images\BannerSignin.png" alt="" />
            </div>
            <div className="Logo">
              <img src="\Super Admin Images\Logo Sqillup.svg" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-content">
              <div className="signin">
                <h1>Welcome back</h1>
              </div>
              <div className="p2">
                <p>Sign in to your account</p>
              </div>
              <div className="p3">
                <p>Enter your email address and password below</p>
              </div>
              <div className="row">
                <form className="Signin_form">
                  {formErrors.userName != undefined &&
                  formErrors.userName.length > 0 ? (
                    <span className="error-msg">
                      <span className="pr-2">
                        <AiOutlineInfoCircle />
                      </span>
                      &nbsp;
                      {/* Invalid Login details */}
                      {formErrors.userName}
                    </span>
                  ) : (
                    <pre></pre>
                  )}

                  <div className="form-group">
                    <label>Enter username</label>
                    <input
                      type="email"
                      id="uname"
                      name="uname"
                      className="form-control"
                      placeholder="Username"
                      onKeyUp={(event) => setUserName(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onKeyUp={(event) => setPassword(event.target.value)}
                    />
                    {/* login error msg */}

                    {formErrors.password != undefined &&
                    formErrors.password.length > 0 ? (
                      <span className="error-msg">
                        <span className="pr-2">
                          <AiOutlineInfoCircle />
                        </span>
                        {formErrors.password}
                      </span>
                    ) : (
                      <pre></pre>
                    )}
                    <span className="error-msg">{errorMessage}</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      formcontrolname="checkbox"
                      id="exampleCheck1"
                      className="form-check-input"
                    />
                    <label htmlFor="exampleCheck1" className="form-check-label">
                      Remember Me
                    </label>
                    <Link to="#" className="forgot">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
                <div className="Signin-Button">
                  <button type="submit" onClick={adminhandelLogin}>
                    SIGN IN
                  </button>
                  <SpinnerCircular enabled={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SigninPage;
