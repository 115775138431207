import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/FinancialManagement.css";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import { couponviewallusers, couponviewinviteusers } from "../Services";
import { Scrollbars } from "react-custom-scrollbars-2";
const scrollBarStyle = {
  width: "500px",
  height: "250px",
  color: "red",
};

function CouponInvitaion({ editcoupon }) {

  const [allusers, setallusers] = useState("");
  const [name, setname] = useState("");
  const [role, setrole] = useState("");
  const [check, setCheck] = useState(false);
  const [couponid, setCouponId] = useState("");
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [useId, setUserId] = useState("");
  const [userIsChecked, setIsChecked] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    couponviewallusers().then((data) => {
      setallusers(data?.data);
      setCouponId(data?.couponid);
      setrole(editcoupon?.role?.name);
      setname(editcoupon?.full_name);
    });
  }, [editcoupon]);

  // function handleCheckboxChange(itemKey) {
  //   const newSelectedItems = new Set(selectedItems);
  //   if (!newSelectedItems.has(itemKey)) {
  //     newSelectedItems.add(itemKey);
  //   } else {
  //     newSelectedItems.delete(itemKey);
  //   }
  //   setSelectedItems(newSelectedItems);
  // }

  const handelSendCouponInvitation = (event) => {

    var FormData = require("form-data");
    var data = new FormData();
    data.append("coupon_id", editcoupon);

    for (var i = 0; i < userIsChecked.length; i++) {
        var id = "user_id[" + i + "]";
        data.append(id, userIsChecked[i]);
    }

    couponviewinviteusers(data)
      .then((responseData) => {
        return responseData?.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  //   const onValueChange = () => {
  //     setCheck(check => !check)
  // }

  // const handleinvitation = (event) => {
  //   var checkList = document.getElementById("listdata");
  //   var items = document.getElementById("itemsdata");
  //   checkList.getElementsByClassName("anchor")[0].onclick = function (evt) {
  //     if (items.classList.contains("visible")) {
  //       items.classList.remove("visible");
  //       items.style.display = "none";
  //     } else {
  //       items.classList.add("visible");
  //       items.style.display = "block";
  //     }
  //   };
  // };

  // const handelgetData = (event, values) => {
  //   var FormData = require("form-data");
  //   var data = new FormData();
  //   data.append("role", values);
  //   couponviewallusers(data).then((data) => {
  //     setallusers(data?.data);
  //     console.log(data?.data);
  //   });
  //   console.log(values);
  // };

  const handelUserClickCheck = e  => {
    let newList = [...userIsChecked, e.target.value];
    if (userIsChecked.includes(e.target.value)) {
      newList = newList.filter(item => item !== e.target.value);
    }
    setIsChecked(newList);
  };

  // CheckBoxes Data
  function checkAll() {
    var inputs = document.querySelectorAll(".check2");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = true;
    }
  }

  function uncheckAll() {
    var inputs = document.querySelectorAll(".check2");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  }

  window.onload = function () {
    window.addEventListener("load", checkAll, false);
  };
  const handelClickClose = (menu) => {
    document.getElementById(menu).style.right = "0px";
  };

  const handleSelect = async (choose) => {
    setrole(choose)

    var FormData = require("form-data");
    var data = new FormData();
    data.append("role", choose);
    const result = await couponviewallusers(data);
    setallusers(result.data)
    console.log(result)
  }

  const handleSearchBox = async (e) => {
    e.preventDefault();
    var FormData = require('form-data');
    var data = new FormData();
    data.append('name', searchText);

    await couponviewallusers(data).then((data) => {
      setallusers(data?.data);
    });
  }

  return (
    <>
      <div className="CouponInvitaion_title">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h5>Coupon Invitation</h5>
            <hr />
          </div>
          <div className="col-md-6">
            <div className="CouponInvitaion_Icon">
              <span onClick={() => handelClickClose("couponInvitation")}>
                <IoMdCloseCircle />
              </span>
            </div>
          </div>
        </div>
        <h6 style={{ fontSize: "14px", color: "#162880", fontWeight: "600" }}>
          {editcoupon?.code}
        </h6>
        <span style={{ fontSize: "12px", color: "#707070" }}>
          {editcoupon?.description}
        </span>
        <div className="col-md-12 d-flex mt-3">
        <input type="hidden" id="couponid" name="couponid" value={couponid} onChnage={(e)=>setCouponId(e.target.value)}/>
          <div className="col-md-4">
            <div className="CouponInvitaion_dropdown">
              <label style={{ color: "#111", fontWeight: "600" }}>
                User Type
              </label>{" "}
              <br />
              <div className="CouponInvitaion_input_form">
                <select
                  value={role}
                  onChange={(e) => handleSelect(e.target.value) }
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="school">School</option>
                  <option value="parent">Parents</option>
                </select>
                {/* <div
                  id="listdata"
                  className="CouponInvitaion-check-list"
                  onClick={handleinvitation}
                >
                  <span className="anchor">Select User Type</span>
                  <ul id="itemsdata" className="items" value={role}>
                    <li>
                      School
                      <input
                        type="checkbox"
                        onChange={(e) => handelgetData("School")}
                      />
                    </li>
                    <li>
                      Parent
                      <input
                        type="checkbox"
                        onChange={(e) => handelgetData("Parent")}
                      />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <br />

            <form className="d-flex CouponInvitaion_Searchbar" onSubmit={handleSearchBox}>
              <button className="CouponInvitaion_serch-icon"
                          style={{top: '5px', left: '60px', backgroundColor: 'transparent',
                                  color: 'black', border: 'none', fontWeight: 'bold'}}>
                  <AiOutlineSearch />
              </button>
              <input
                type="search"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="CouponInvitaion_checkbox_wrapper">
          <form>
            <div className="d-flex">
              <input
                type="button"
                onClick={checkAll}
                className="btn_Select"
                value="Select All"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="checkbox"
                className="check2"
                onClick={checkAll}
              />
              <input
                type="button"
                onClick={uncheckAll}
                className="btn_reset "
                value="Reset"
              />
            </div>
            <Scrollbars vertical autoHide={false} style={scrollBarStyle}>
              {allusers.length > 0 &&
                allusers.map((invitation, key) => (
                  <div className="checkbox_wrapper" key={key}>
                    <div className="checkbox">
                      <label>JK</label>
                      <span>{invitation.first_name}</span>
                      <input
                        type="checkbox"
                        onChange={(e) => handelUserClickCheck(e)}
                        // onChange={handelCheckChange}
                        //checked={userIsChecked.includes(invitation?.id)}
                        value={invitation?.id}
                        className="check2"
                      />
                      {/* <input
                      type="checkbox"
                      className="check2"
                      onClick={(e) => handelUserClickCheck(e, invitation)}
                    /> */}
                    </div>
                  </div>
                ))}
            </Scrollbars>
          </form>
        </div>

        <div className="CouponInvitaion_button mb-5">
          <button onClick={handelSendCouponInvitation}>Send Coupon</button>
        </div>
      </div>
    </>
  );
}

export default CouponInvitaion;
