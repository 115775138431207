import React from 'react'

function Curriculum() {
  return (
    <div className="dashboard">
        <h1>Curriculum</h1>
    </div>
  )
}

export default Curriculum